.rigorous-div{
  align-items: center;
  margin-bottom: 40px;
  .rigorous-title{
    width: 240px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid black;
    cursor: pointer;
    transition: all .2s;
    &:hover{
      border: 1px solid #e8e8e800;
      background: linear-gradient(90deg, #FFCC0C, #F6BB07);
      box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
    }
  }
  .rigorous-line{
    width: 60px;
    height: 1px;
    background-color: black;
    margin:  0 13px;
  }
}
.logical-system-box1{
  width: 290px;
  height: 100px;
  position: relative;
}
.logical-system-title-1{
  width: 270px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:24px;
  color: #333333;
  border: 1px solid #E8E8E8;
}
.logical-system-box1 span{
  display: block;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 20px solid transparent;
  position: absolute;
  right: 1px;
  opacity: 0;
  top: 30px;
}
.logical-system-box-active-1 .logical-system-title-1{
  transition: all .4s;
  background: #FAC209;
  font-weight: 900;
  border: none;
}
.logical-system-box-active-1 span{
  transition: all .4s;
  opacity: 1;
  border-left: 20px solid #FAC209;
}
.logical-system-right-1{
  flex: 1;
  height: 555px;
  display: block;
  padding:50px 90px 0 72px;
}
.logical-system-title1-1{
  transition: all .4s;
  position: absolute;
  top: 435px;
  left: 0;
  right: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}
.logical-system-title2-1{
  transition: all .4s;
  position: absolute;
  top: 475px;
  left: 0;
  right: 0;
  font-size: 20px;
  color: #707070;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}
.logical-system-img-1{
  transition: all 0.4s;
  position: absolute;
  opacity: 0;
  top:0;
}
.logical-system-img-box-1{
  position: relative;
}
.logical-system-img-active-1{
  transition: all 0.4s;
  opacity: 1;
  z-index: 99;
}
.logical-system-title1-active-1{
  transition: all .4s;
  opacity: 1;
}
.logical-system-title2-active-1{
  transition: all .4s;
  opacity: 1;
}
.operating-model{
  display: flex;
  justify-content: space-between;
  .operating-model-box{
    width: 460px;
    height: 710px;
    background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
    box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: all 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding:0 30px;
    padding-top: 60px;
    text-align: center;
    .operating-model-box-tag{
      width: 190px;
      height: 50px;
      margin-bottom: 16px;
      font-size: 20px;
      color: #333333;
      border: 1px solid #E8E8E8;
      background: #F8F8F8;
      border-radius: 6px;
      transition: all 0.4s;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .operating-model-box-more{
      width: 220px;
      height: 60px;
      border: 2px solid #17191A;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 60px;
      left: 120px;
      font-size: 26px;
      color: #17191A;
      transition: all 0.4s;
    }
    &:hover{
      background: #FFFFFF;
      box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0.08);
      transition: all 0.4s;
    }
    &:hover .operating-model-box-tag{
      border: 1px solid #F8F8F8;
      transition: all 0.4s;
    }
    &:hover .operating-model-box-more{
      border: 2px solid #F6BB07;
      background: linear-gradient(90deg, #FFCC0C, #F6BB07);
      box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
      transition: all 0.4s;
    }
  }
}