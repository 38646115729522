.scope-content-bottom {
  position: absolute;
  width: 100%;
  line-height: 25px;
  padding: 0 25px 0px 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  top: 250px;
}

.service-modulesC-text {
  width: 737px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: #121213;
}

.service-flow-box {
  width: 180px;
  height: 164px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  background: #F6BB07;
  padding: 30px 27px 0 30px;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  position: relative;
}
.service-flow-box .service-flow-arrows {
  position: absolute;
  width: 20px;
  height: 36px;
  right: -19px;
  top: 86px;
  z-index: 2;
}
.service-flow-box .display-none {
  display: none;
}