/** 发展历程 */
.developing-cause {
  width: 100%;
  height: 830px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}

/** 发展历程 #636262 rgb(0, 0, 0, .7) */
.culture-banner {
  color: #636262B3;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.7) 0%, rgba(0, 0, 0, 0.7) 20%);
}

.culture-content {
  padding-left: 75px;
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

/** 联系我们 */

.user-info-submit {
  cursor: pointer;
  width: 568px;
  height: 68px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 34px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 26px;
  color: #121213;
  line-height: 68px;
  text-align: center;
  letter-spacing: 1px;
  user-select: none;
}

.input-default {
  background-color: #F8F8F8;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #A2A0A0;
  line-height: 32px;
}

.textarea-default {
  background-color: #F8F8F8;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #A2A0A0;
  line-height: 32px;
  max-height: 180px;
  resize: none;
  letter-spacing: .5px;
  overflow: hidden;
}

/** 公用 */
.hover_checked {
  background: linear-gradient(-90deg, #FFCC0C, #F5AD01);
  box-shadow: 0px 8px 20px 0px rgba(245, 173, 1, 0.4);
}

.middle-container {
  margin: 0 auto;
  width: 80%;
}

.flex {
  display: flex;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-between {
  justify-content: space-between;
}

.pb-30 {
  padding-bottom: 30px
}

.w-1\/5 {
  width: 20%;
}

.w-\[770px\] {
  width: 770px;
}
.h-\[175px\] {
  height: 175px;
}
.m-0 {
  margin: 0;
}
.bg-fff{
  background-color: #fff;
}
.mt-\[55px\] {
  margin-top: 55px;
}
.mt-\[85px\] {
  margin-top: 85px;
}
.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-\[10px\] {
  border-radius: 10px;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.w-\[238px\] {
  width: 238px;
}
.box-border {
  box-sizing: border-box;
}
.text-\[28px\] {
  font-size: 28px;
}
.ml-\[23px\] {
  margin-left: 23px;
}
.ml-\[25px\] {
  margin-left: 25px;
}
.text-\[\#E8E8E8\] {
  --tw-text-opacity: 1;
  color: rgb(232 232 232 );
}
.text-\[32px\] {
  font-size: 32px;
}
.text-\[28px\] {
  font-size: 28px;
}
.mt-\[10px\] {
  margin-top: 10px;
}
.mt-\[155px\] {
  margin-top: 155px;
}
.duration-\[\.8s\] {
  transition-duration: .8s;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}
.ml-\[25px\] {
  margin-left: 25px;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.hover\:scale-\[1\.03\]:hover {
  --tw-scale-x: 1.03;
  --tw-scale-y: 1.03;
}
.text-\[\#E8E8E8\] {
  --tw-text-opacity: 1;
  color: rgb(232 232 232 / var(--tw-text-opacity));
}
.min-w-\[1440px\] {
  min-width: 1440px;
}
.w-\[100\%\] {
  width: 100%;
}

.h-\[auto\] {
  height: auto;
}
.mt-\[60px\] {
  margin-top: 60px;
}
.w-\[100\%\] {
  width: 100%;
}

.h-\[656px\] {
  height: 656px;
}
.top-\[-10px\] {
  top: -10px;
}
.relative {
  position: relative;
}
.w-\[150px\] {
  width: 150px;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.absolute {
  position: absolute;
}
.h-\[800px\] {
  height: 800px;
}
.-top-1 {
  top: -.25rem;
}
.h-\[660px\] {
  height: 660px;
}
.h-\[656px\] {
  height: 656px;
}
.top-\[-10px\] {
  top: -10px;
}
.h-\[800px\] {
  height: 800px;
}
.-top-1 {
  top: -.25rem;
}
.h-\[100\%\] {
  height: 100%;
}
.w-\[400px\] {
  width: 400px;
}
.left-\[40px\] {
  left: 40px;
}
.duration-\[1s\] {
  transition-duration: 1s;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}
.bg-\[\#1887E633\] {
  background-color: #1887e633;
}
.h-\[150px\] {
  height: 150px;
}
.items-center {
  align-items: center;
}
.box-border {
  box-sizing: border-box;
}
.mb-\[15px\] {
  margin-bottom: 15px;
}
.text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.mt-\[180px\] {
  margin-top: 180px;
}
.flex-col {
  flex-direction: column;
}
.font-bold {
  font-family: SansHansBold;
}
.text-\[\#FFFFFF\], .text-\[\#FFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-bold {
  font-weight: 700;
}
.text-\[32px\] {
  font-size: 32px;
}
.font-light {
  font-family: SansHansLight;
}
.text-\[\#FFFFFF\], .text-\[\#FFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.font-light {
  font-weight: 300;
}
.text-\[28px\] {
  font-size: 28px;
}
.leading-\[32px\] {
  line-height: 32px;
}
.font-bold {
  font-weight: 700;
}

.text-\[78px\] {
  font-size: 78px;
}
.h-\[60px\] {
  height: 60px;
}
.mb-\[40px\] {
  margin-bottom: 40px;
}
.text-\[48px\] {
  font-size: 48px;
}
.h-\[50px\] {
  height: 50px;
}
.py-\[60px\] {
  padding-top: 60px;
  padding-bottom: 60px;
}
.h-\[480px\] {
  height: 480px;
}
.w-\[390px\] {
  width: 390px;
}
.h-\[50px\] {
  height: 50px;
}
.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}
.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}
.bg-\[\#F8F8F8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.rounded-\[10px\] {
  border-radius: 10px;
}
.input-default{
  background-color: #f8f8f8;
  font-weight: 400;
  color: #a2a0a0;
  line-height: 32px;
  font-family: Source Han Sans CN;
  font-size: 20px;
}
.border-none {
  border-style: none;
}
.border {
  border-width: 1px;
}

.text-\[\#121213\] {
  --tw-text-opacity: 1;
  color: rgb(18 18 19 / var(--tw-text-opacity));
}

.leading-\[32px\] {
  line-height: 32px;
}
.font-bold {
  font-weight: 700;
}
.text-\[20px\] {
  font-size: 20px;
}
.rounded {
  border-radius: .25rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.mr-\[14px\] {
  margin-right: 14px;
}
[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
  touch-action: manipulation;
}
[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
  touch-action: manipulation;
}
input[type=number], input[type=password], input[type=text], textarea {
  -webkit-appearance: none;
}
input{
  outline-style: none;
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  background:none;
  outline:none;
  border:none;
}
textarea{
  outline-style: none;
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  background:none;
  outline:none;
  border:none;
}
input:focus { outline: none; }
.mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px;
}
.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}
.px-\[28px\] {
  padding-left: 28px;
  padding-right: 28px;
}
.box-border {
  box-sizing: border-box;
}