
.pages-nonactivated{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 84px;
  border-bottom:4px solid #E8E8E8;
  transition: all 0.4s;
  cursor: pointer;
  p{
    font-size: 28px;
    color: #121213;
  }
}

.pages-activate{
  border-bottom:4px solid #F6BB07;
  transition: all 0.4s;
  p{
    font-weight: bold;
  }
}
.campus-platform-moduleA-top{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: #333333;
  width: 154px;
  height: 154px;
  background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
  border-radius: 10px;
  margin-bottom: 28px;
  img{
    margin-bottom: 14px;
  }
}

.campus-platform-moduleB-box{
  background: #FFFFFF;
  box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #121213;
  font-weight: bold;
}
.campus-platform-moduleB-list{
  width: 445px;
  height: 80px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 30px;
}
.campus-platform-moduleB-bg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto;
  width: 500px;
  height: 400px;
  z-index: 1;
}
.campus-platform-moduleD-box{
  width: 705px;
  height: 160px;
  border-radius: 10px;
  padding:20px 30px 0 30px;
  p{
    font-size: 16px;
    color: #707070;
    line-height: 25.5px;
  }
}
.campus-platform-moduleD-box-title{
  width: 236px;
  height: 50px;
  border-radius: 30px 10px 30px 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #121213;
  margin-bottom: 10px;
  img{
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}