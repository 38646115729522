.multi-platform-moduleD-circle {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 90px;
}