.download-center{
  display: flex;
  align-items: center;
}


.download-yellow-btn{
  cursor: pointer;
  width: 156px;
  height: 36px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #17191A;
  margin-right: 20px;
  position: relative;
  img{
    position: absolute;
    left: 0;
    top:36px;
    width: 150px;
    height: 0px;
    opacity: 0;
  }
}
.download-yellow-btn:hover img{
  height: 150px;
  opacity: 1;
  transition: all 0.4s;
  z-index: 99;
}
.download-hover-btn{
  width: 156px;
  height: 36px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #17191A;
  margin-right: 20px;
  transition: all 0.4s;
  position: relative;
  cursor: pointer;
  img{
    position: absolute;
    left: 0;
    top:36px;
    width: 150px;
    height: 0px;
    opacity: 0;
  }
}
.download-hover-btn:hover {
  background: #F6BB07 !important;
  color: #17191A;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
  transition: all 0.4s;
  img{
    height: 150px;
    opacity: 1;
    transition: all 0.4s;
    z-index: 99;
  }
}