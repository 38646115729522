@charset "UTF-8";
.plate1Banner {
  background: url("../asset/self/banner.png");
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}
.plate1Banner .iconBanner {
  display: flex;
  align-items: center;
}
.plate1Banner .iconBanner .iconBannerItem {
  margin-top: 70px;
  padding: 13px 10px 17px 11px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.plate1Banner .iconBanner .iconBannerItem img {
  width: 40px;
  height: 40px;
}
.plate1Banner .iconBanner .iconBannerItem span {
  margin-top: 10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: #FFFFFF;
}

.plate2Text {
  background: rgba(68, 132, 253, 0.1);
  padding: 20px 20px 20px 30px;
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  width: 869px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 16px;
  color: #121213;
  line-height: 28px;
  margin-bottom: 50px;
}

.plate2Text:after {
  position: absolute;
  left: -40px;
  top: 50%;
  /* 在对话框的垂直中间 */
  transform: translateY(-50%);
  /* 上移50%以居中 */
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid rgba(68, 132, 253, 0.1);
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
}

.plate3Text {
  margin-left: 20px;
}
.plate3Text .plate3TextItem {
  width: 510px;
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  padding: 24px 30px;
  color: #121213;
  line-height: 28px;
  margin-bottom: 30px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: #121213;
}
.plate3Text .plate3TextItem .font20 {
  font-size: 20px;
}
.plate3Text .plate3TextItem .font16 {
  font-size: 16px;
}
.plate3Text .plate3TextItem:last-child {
  margin-bottom: 0px;
}
.plate3Text .plate3TextItem:hover {
  font-weight: bold;
  background: linear-gradient(90deg, #fffdf8, #fef8e6);
  border: 1px solid transparent;
}
.plate3Text .plate3TextItem:hover .plate3TextTip {
  position: absolute;
  left: 0;
  top: 20px;
  bottom: 20px;
  width: 4px;
  background: #F6BB07;
}

.plate3 {
  width: 1277px;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.plate3 .plate3Left {
  width: 287px;
  height: 200px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  color: #333333;
}
.plate3 .plate3Left img {
  width: auto;
  height: 70px;
}
.plate3 .plate3Left span {
  margin-top: 26px;
}
.plate3 .plate3Left:first-child {
  border-radius: 20px 0px 0px 0px;
  border-bottom: none;
}
.plate3 .plate3Left:last-child {
  border-top: none;
  border-radius: 0px 0px 0px 20px;
}
.plate3 .plate3LeftActive {
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  position: relative;
  font-weight: bold;
}
.plate3 .plate3LeftActive:after {
  position: absolute;
  right: -40px;
  top: 50%;
  /* 在对话框的垂直中间 */
  transform: translateY(-50%);
  /* 上移50%以居中 */
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid #F6BB07;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
}

.plate4 {
  width: 1440px;
  height: 294px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 0 auto;
  padding: 40px;
}
.plate4 .plate4Title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #17191A;
  position: relative;
}
.plate4 .plate4Title::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 350px;
  height: 15px;
  content: "";
  background: rgba(246, 187, 7, 0.15);
}
.plate4 .plate4Btn {
  width: 568px;
  height: 68px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 34px;
  font-size: 26px;
  color: #17191A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
  cursor: pointer;
}
.plate4 .plate4Btn:hover {
  opacity: 0.8;
}

.fC6 {
  color: #1887E6;
}