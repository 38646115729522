.Carousel-index {
  position: relative;
  height: 680px;
}
.Carousel-index .Carousel-content {
  position: absolute;
  height: 100%;
  max-width: 1440px;
  min-width: 1300px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
}
.Carousel-index .Carousel-content .Carousel-item {
  margin-top: 239px;
}
.Carousel-index .Carousel-content .Carousel-item h3 {
  margin: 0;
  margin-bottom: 5px;
}
.Carousel-index .Carousel-content .Carousel-item h3 span {
  font-size: 36px;
  font-weight: 700;
}
.Carousel-index .Carousel-content .Carousel-item h3 .colorText {
  color: #F6BB07;
}
.Carousel-index .Carousel-content .Carousel-item h3 .Text {
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-item .desc {
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-item .scenes {
  height: 40px;
  background: #4942FF;
  border-radius: 20px;
  display: inline-block;
  padding: 0 30px;
  margin-top: 25px;
}
.Carousel-index .Carousel-content .Carousel-item .scenes img {
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.Carousel-index .Carousel-content .Carousel-item .scenes span {
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 40px;
  margin-right: 26px;
  vertical-align: middle;
}
.Carousel-index .Carousel-content .Carousel-item .scenes span:nth-last-child(1) {
  margin-right: 0;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes-div {
  display: flex;
}
.Carousel-index .Carousel-content .Carousel-item .characteristic span {
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 20px;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes {
  margin-top: 25px;
  width: 100px;
  height: 100px;
  background: rgba(64, 86, 255, 0.4);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes img {
  width: 30px;
  height: 30px;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes span {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes-gray {
  margin-top: 25px;
  width: 100px;
  height: 100px;
  background: rgba(75, 88, 139, 0.4);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes-gray img {
  width: 30px;
  height: 30px;
}
.Carousel-index .Carousel-content .Carousel-item .iconscenes-gray span {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-item .Carousel-button {
  width: 367px;
  height: 56px;
  background: #FFFFFF;
  border-radius: 28px;
  margin-top: 82px;
  justify-content: space-between;
  padding: 6px 8px;
  overflow: hidden;
}
.Carousel-index .Carousel-content .Carousel-item .Carousel-button .button {
  width: 160px;
  height: 44px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 700;
  color: #121213;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}
.Carousel-index .Carousel-content .Carousel-item .Carousel-button input {
  outline: none;
  border: none;
  padding-left: 41px;
  font-style: italic;
}
.Carousel-index .Carousel-content .Carousel-item .Carousel-button input::-webkit-outer-spin-button, .Carousel-index .Carousel-content .Carousel-item .Carousel-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.Carousel-index .Carousel-content .Carousel-item .Carousel-button input[type=number] {
  -moz-appearance: textfield;
}
.Carousel-index .Carousel-content .Carousel-douyin {
  margin-top: 179px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Carousel-index .Carousel-content .Carousel-douyin .textTwo {
  margin: 0 auto;
  width: 600px;
  height: 100px;
  background: #1A1D2F;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 130px;
}
.Carousel-index .Carousel-content .Carousel-douyin .textTwo span {
  font-size: 20px;
}
.Carousel-index .Carousel-content .Carousel-douyin h3 {
  margin: 0;
  margin-bottom: 49px;
  display: flex;
  justify-content: center;
}
.Carousel-index .Carousel-content .Carousel-douyin h3 span {
  font-size: 36px;
  font-weight: 700;
}
.Carousel-index .Carousel-content .Carousel-douyin h3 .colorText {
  color: #F6BB07;
}
.Carousel-index .Carousel-content .Carousel-douyin h3 .Text {
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-douyin span {
  font-size: 36px;
  font-weight: 700;
}
.Carousel-index .Carousel-content .Carousel-douyin .colorText {
  color: #F6BB07;
}
.Carousel-index .Carousel-content .Carousel-douyin .Text {
  color: #FFFFFF;
}
.Carousel-index .Carousel-content .Carousel-douyin .Carousel-button {
  width: 367px;
  height: 56px;
  background: #FFFFFF;
  border-radius: 28px;
  margin-top: 82px;
  justify-content: space-between;
  padding: 6px 8px;
  overflow: hidden;
}
.Carousel-index .Carousel-content .Carousel-douyin .Carousel-button .button {
  width: 160px;
  height: 44px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 700;
  color: #121213;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}
.Carousel-index .Carousel-content .Carousel-douyin .Carousel-button input {
  outline: none;
  border: none;
  padding-left: 41px;
  font-style: italic;
}
.Carousel-index .Carousel-content .Carousel-douyin .Carousel-button input::-webkit-outer-spin-button, .Carousel-index .Carousel-content .Carousel-douyin .Carousel-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.Carousel-index .Carousel-content .Carousel-douyin .Carousel-button input[type=number] {
  -moz-appearance: textfield;
}