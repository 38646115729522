.Carousel-index{
  position: relative;
  height: 680px;
  .Carousel-content{
    position: absolute;
    height: 100%;
    max-width: 1440px;
    min-width: 1300px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    .Carousel-item{
      margin-top: 239px;
      h3{
        margin: 0;
        margin-bottom:5px;
        span{
          font-size: 36px;
          font-weight: 700;
        }
        .colorText{
          color: #F6BB07;
        }
        .Text{
          color: #FFFFFF
        }
      }
      .desc{
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .scenes{
        height: 40px;
        background: #4942FF;
        border-radius: 20px;
        display: inline-block;
        padding: 0 30px;
        margin-top: 25px;
        img{
          width: 28px;
          height: 28px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
        }
        span{
          font-size: 20px;
          font-weight: 700;
          color: #FFFFFF;
          line-height: 40px;
          margin-right: 26px;
          vertical-align: middle;
          &:nth-last-child(1){
            margin-right: 0;
          }
        }
      }
      .iconscenes-div{
        display: flex;
      }
      .characteristic span{
        font-size: 20px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      .iconscenes{
        margin-top: 25px;
        width: 100px;
        height: 100px;
        background: rgba(#4056FF,0.4);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img{
          width: 30px;
          height: 30px;
        }
        span{
          margin-top: 5px;
          font-size: 20px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .iconscenes-gray{
        margin-top: 25px;
        width: 100px;
        height: 100px;
        background: rgba(#4B588B,0.4);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img{
          width: 30px;
          height: 30px;
        }
        span{
          margin-top: 5px;
          font-size: 20px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .Carousel-button{
        width: 367px;
        height: 56px;
        background: #FFFFFF;
        border-radius: 28px;
        margin-top: 82px;
        justify-content:space-between;
        padding:6px 8px;
        overflow: hidden;
        .button{
          width: 160px;
          height: 44px;
          background: linear-gradient(90deg, #FFCC0C, #F6BB07);
          box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
          border-radius: 22px;
          font-size: 16px;
          font-weight: 700;
          color: #121213;
          line-height: 44px;
          text-align: center;
          cursor: pointer;
        }
        input{
          outline: none;
          border: none;
          &::-webkit-outer-spin-button, &::-webkit-inner-spin-button { -webkit-appearance: none; }
          &[type="number"]{ -moz-appearance: textfield; }
          padding-left: 41px;
          font-style: italic;
        }
      }
    }
    .Carousel-douyin{
      margin-top: 179px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .textTwo{
        margin: 0 auto;
        width: 600px;
        height: 100px;
        background: #1A1D2F;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 130px;
        span{
          font-size: 20px;
        }
      }
      h3{
        margin: 0;
        margin-bottom:49px;
        display: flex;
        justify-content: center;
        span{
          font-size: 36px;
          font-weight: 700;
        }
        .colorText{
          color: #F6BB07;
        }
        .Text{
          color: #FFFFFF
        }
      }
      span{
          font-size: 36px;
          font-weight: 700;
        }
        .colorText{
          color: #F6BB07;
        }
        .Text{
          color: #FFFFFF
        }
      .Carousel-button{
        width: 367px;
        height: 56px;
        background: #FFFFFF;
        border-radius: 28px;
        margin-top: 82px;
        justify-content:space-between;
        padding:6px 8px;
        overflow: hidden;
        .button{
          width: 160px;
          height: 44px;
          background: linear-gradient(90deg, #FFCC0C, #F6BB07);
          box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
          border-radius: 22px;
          font-size: 16px;
          font-weight: 700;
          color: #121213;
          line-height: 44px;
          text-align: center;
          cursor: pointer;
        }
        input{
          outline: none;
          border: none;
          &::-webkit-outer-spin-button, &::-webkit-inner-spin-button { -webkit-appearance: none; }
          &[type="number"]{ -moz-appearance: textfield; }
          padding-left: 41px;
          font-style: italic;
        }
      }
    }
  }
}