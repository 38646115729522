 // 多元模板
.detaile-template{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  .box{
    width: 215px;
    height: 215px;
    background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #121213;
  }
}

 // 服务场景
.service-scenario{
  .scenario-left{
    background:linear-gradient(-90deg, #121213 0%, rgba(18, 18, 19, 0.9 ) 100%);
    width:100%;
    top:0;
    left:0;
    height:100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 60px;
    border-radius:0 20px 0 0;
    overflow: hidden;
    p{
      font-size: 24px;
      font-style: italic;
      color: rgba($color: #FFFFFF, $alpha: 0.4);
      margin-bottom: 0;
      margin-right: 20px;
    }
    img{
      width: 60px;
      height: 60px;
      opacity: 0.3;
    }
    
  }
  .active .scenario-left{
    background:linear-gradient(-90deg, #121213 0%, rgba(0, 0, 0, 0.2 ) 100%);
    transition:  all .4s;
    p{
      color: rgba($color: #FFFFFF, $alpha: 1);
      font-weight: bold;
      text-shadow: 0px 10px 20px #333333;
      transition:  all .4s;
    }
    img{
      opacity: 1;
      transition:all .4s;
    }
  }
  .scenario-right{
    border-radius: 20px 0 0 0;
    overflow: hidden;
    background:linear-gradient(90deg, #121213 0%, rgba(18, 18, 19, 0.9) 100%);
    width:100%;
    top:0;
    left:0;
    height:100%;
    align-items: center;
    padding-left: 60px;
    p{
      font-size: 24px;
      font-style: italic;
      color: rgba($color: #FFFFFF, $alpha: 0.4);
      margin-bottom: 0;
    }
    img{
      width: 60px;
      height: 60px;
      margin-right: 20px;
      opacity: 0.3;
    }
  }
  .active .scenario-right{
    background:linear-gradient(90deg, #121213 0%, rgba(18, 18, 19, 0.2) 100%);
    transition:  all .4s;
    p{
      color: rgba($color: #FFFFFF, $alpha: 1);
      font-weight: bold;
      text-shadow: 0px 10px 20px #333333;
      transition:  all .4s;
    }
    img{
      opacity: 1;
      transition:  all .4s;
    }
  }
}
.service-scope-top{
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  flex-wrap:wrap;
  .box{
    height: 338px;
    width: 338px;
    border-radius: 20px;
    position:relative;
    overflow: hidden;
    .scope-content{
      width: 100%;
      height: 100%;
      position:absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .scope-content-title{
        width: 190px;
        height: 60px;
        background: linear-gradient(90deg, #FFCC0C, #F6BB07);
        border-radius: 0px 0px 10px 10px;
        font-weight: bold;
        color: #121213;
        font-size: 24px;
        text-align: center;
        line-height: 60px;
      }
    }
    .box-warp{
      transform: scale(1);
      transition: all 0.4s;
      
    }
  }
  .box:hover .box-warp{
    transform: scale(1.3);
    transition: all 0.4s;
  }
  
}
.width460{
  width: 460px !important;
}

.valueWindow-top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.valueWindow-bottom{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.valueWindow-list{
  width: 400px;
  height: 276px;
  background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:0 34px 0 40px;
  img{
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  p{
    text-align: center;
  }
}