@media screen and (max-width: 767px) {

  .banner-title .fadeInRight {
    animation-name: fadeInDown;
  }

  .banner-main {
    justify-content: center !important;
    text-align: center;
  }

  .banner-main .banner-title {
    margin: 0;
  }

  .banner-main .banner-title .banner-text {
    font-size: 22px;
  }

  .banner-main .banner-title .banner-text-span {
    font-size: 14px;
  }

  .banner-main .banner-title .banner-btn {
    margin-top: 100px;
    width: 140px;
    height: 40px;
    font-size: 15px;
  }

  .main .title {
    margin-top: 40px;
    font-size: 18px;
  }

  .main .notjust-img,
  .main-2 .notjust-img {
    width: 204px;
    height: 34px;
  }

  .title2-img {
    width: 250px !important;
    height: 34px !important;
  }

  .title3-img {
    width: 250px !important;
    height: 34px !important;
    position: static !important;
  }

  .main-2 .banner2 {
    width: 320px;
    height: 135px;
  }

  .comprehensive-img {
    width: 300px !important;
    height: 35px !important;
  }

  .main-title {
    font-size: 26px !important;
  }

  .main-3 .tag {
    font-size: 14px;
  }

  .main-3 {
    display: none !important;
  }

  .service-left-img {
    padding: 0 !important;
  }
}
.flex_column{
  flex-direction: column;
}
/** Home **/
.home-banner {
  /* background: url('./asset/banner.png'); */
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

.home-banner2 {
  /* background: url('./asset/home_banner2.png'); */
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

.home-banner3 {
  /* background: url('./asset/home_banner3.png'); */
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

/**caselist**/
.caseListBanner {
  /* background: url('./asset/caselist/banner.png'); */
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

/**customized**/
.customizedBanner {
  /* background: url('./asset/customized/banner.png'); */
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

/** Detail **/
.detaile-banner {
  background: url('./asset/detaile/banner.png');
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

/**information**/
.information-banner {
  background: url('./asset/information/banner.png');
  background-size: 100% 100%;
  height: 600px;
  width: 100%;
}

/**about**/
.about-banner {
  background: url('./asset/about/banner.png');
  background-size: 100% 100%;
  height: 680px;
  width: 100%;
}

.img-fluid {
  width: 470px;
  height: 600px;
}

@media screen and (max-width: 1440px) {
  .home-banner {
    /* background: url('./asset/banner.png'); */
    background-size: 100% 100%;
    height: 480px;
  }

  .home-banner2 {
    background-size: 100% 100%;
    height: 480px;
  }
 
  .home-banner3 {
    /* background: url('./asset/home_banner3.png'); */
    background-size: 100% 100%;
    height: 480px;
  }

  /**caselist**/
  .caseListBanner {
    /* background: url('./asset/caselist/banner.png'); */
    background-size: 100% 100%;
    height: 480px;
  }

  /**customized**/
  .customizedBanner {
    /* background: url('./asset/customized/banner.png'); */
    background-size: 100% 100%;
    height: 480px;
  }

  /** Detail **/
  .detaile-banner {
    background: url('./asset/detaile/banner.png');
    background-size: 100% 100%;
    height: 480px;
  }

  /**information**/
  .information-banner {
    background: url('./asset/information/banner.png');
    background-size: 100% 100%;
    height: 480px;
  }

  /**about**/
  .about-banner {
    background: url('./asset/about/banner.png');
    background-size: 100% 100%;
    height: 480px;
  }
}


.home-plate2-btn {
  width: 210px;
  height: 50px;
  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 25px;
  font-size: 22px;
  color: #A2A2A2;
  transition: all .3s;
  cursor: pointer;
}

.home-plate2-span {
  width: 62px;
  border: 0.5px solid rgba(232, 232, 232, 1);
  margin: 0 15px;
}

.home-plate2-btn-active {
  transition: all .3s;
  background: #F6BB07;
  color: #765429;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border:1px solid #F6BB07;
}
.home-product-centre{
  width: 824px;
  height: 80%;
  position:absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #FFFFFF;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding:40px 110px 0 100px;
}
.product-centreA{
  left: 44.5%;
}
.product-centreB{
  left: 12.5%;
}

.blate3-block{
  width: 410px;
  height: 160px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin: 15px;
  animation-name: fadeInUp;
}

.blate3-block-title{
  width: 224px;
  height: 80px;
  position: relative;
  box-sizing: border-box;
  padding:5px 0;
}
.blate3-block-span {
  transition: all 0.4s;
  width: 40px;
  height: 4px;
  background: rgba(246, 187, 7, 1);
  background: #fff;
  border-radius: 2px;
  position: absolute;
  bottom: -30px;
}
.blate3-block-p {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin: 0;
  margin-top: 5px;
}
.blate3-block-p2 {
  transition: all 0.4s;
  font-size:16px;
  color: #A1A0A0;
  margin: 0;
  position: absolute;
  top: 45px;
}
.blate3-block:hover .blate3-block-span{
  transition: all 0.4s;
  background: rgba(246, 187, 7, 1);
  bottom: 0;
}
.blate3-block:hover .blate3-block-p2 {
  transition: all 0.4s;
  color: #fff;
  bottom: -15px;
}

.blate3-animate-img {
  width: 80px;
  height: 80px;
  margin-right: 30px;
}

.blate4-dec {
  /*display: none;*/
  opacity: 0;
  transition: all .3s;
}

.blate3-block:hover .isBounce {
  animation-name: bounce;
}

.blate4-img-mask {
  position: relative;
  width: 100%;
  background: #fff;
  top: 0px;
  transition: all .3s;
}

.blate4-car {
  box-shadow: 1px 8px 30px 3px rgba(162, 162, 162, 0.2);
  overflow: hidden;
  border-radius: 10px;
}

.blate4-car:hover .blate4-img-mask {
  top: -150px;
  transition: all .3s;
}

.blate4-car:hover .blate4-dec {
  opacity: 1;
  transition: all .3s;
}

.blate4-mask-round {
  width: 60px;
  height: 60px;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1));
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 30px;
  position: relative;
  top: -30px;
}

.blate4-mask-p{
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
}

.blate4-mask-span {
  font-weight: 400;
  font-size: 18px;
  color: #707070;
  height: 0;
}

.blate4-row-top-img {
  display: none;
}

.blate4-car:hover .blate4-row-top-img {
  display: block;
}

.blate5 .ant-carousel .slick-track {
  height: 510px
}

.banner-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.banner-title {
  /* width: 570px; */
}

.banner-btn {
  margin-top: 177px;
  width: 210px;
  height: 58px;
  border-radius: 29px;
  font-size: 24px;
  font-weight: 400;
  color: #765429;
  border: 1px solid #765429;
}

.banner-btn:hover,
.banner-btn:focus {
  background: #765429 !important;
  color: #FAC209;
  border: 1px solid #765429;
}

.banner-text {
  font-weight: bold;
  color: rgba(118, 84, 41, 1);
  font-size: 44px;
}
.banner-text-spanA{
  color: rgba(118, 84, 41, 1);
  font-weight: 300;
  letter-spacing: 5px;
  font-size: 24px;
  font-weight: 400;
}
.banner-text-span {
  color: rgba(118, 84, 41, 1);
  font-weight: 300;
  
  font-size: 24px;
  font-weight: 400;
}

.title {
  margin-top: 80px;
  font-size: 36px;
  color: rgba(51, 51, 51, 1);
}

.notjust-img {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  width: 408px;
  height: 68px;
  margin: 0 auto;
}

.title2-img {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  width: 554px;
  height: 84px;
  margin: 0 auto;
}

.title3-img {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  width: 556px;
  height: 68px;
  margin: 0 auto;
}

.delivery-box {
  width: 300px;
  height: 365px;
  background: linear-gradient(0deg, rgba(248, 248, 248, 1), rgba(255, 255, 255, 1));
  border-radius: 10px;
  transition: box-shadow .5s;
}

.text-describe {
  width: 205px;
  margin-top: 40px;
  font-size: 18px;
  color: #A2A2A2;
}

.text-bottom-describe {
  width: 100px;
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  transition: all .3s;
}


.main-2-backgroud{
  position:absolute;
  height: 500px;
  width: 100%;
  top: 166px;
  background:linear-gradient(135deg,rgba(255,204,12,1),rgba(246,187,7,1));
}


.delivery-box:hover {
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  transition: box-shadow .5s;
  background: #fff;
}

.delivery-box:hover .text-describe {
  color: #333333;
  transition: all .3s;
}

.delivery-box:hover .text-bottom-describe {
  width: 210px;
  height: 58px;
  color: #fff;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1));
  border-radius: 30px 30px 0px 0px;
  position: relative;
  bottom: -6px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  transition: all .5s;
}

.banner2 {
  width: 1290px;
  height: 558px;
  margin-top: 50px;
}

.detaile-banner1 {
  width: 1290px;
  height: 506px;
  background: url('./asset/detaile/banner1.png');
  background-size: 100% 100%;
  position: absolute;
}

.detaile-banner1 .detaile-main-left-1 {
  position: absolute;
  top: 42px;
  left: 60px;
}

.detaile-plate3-title {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  line-height: 29px;
}

.detaile-banner1 .detaile-main-right-1 {
  position: absolute;
  top: 69px;
  right: 69px;
}

.detaile-banner1 .detaile-main-right-2 {
  position: absolute;
  top: 330px;
  right: 69px;
}

.main-3 {
  padding-top: 80px;
  background-color: #F8F8F8;
  padding-bottom: 80px;
}

.detaile-plate3-dec {
  color: #707070;
  font-size: 14px;
  width: 148px;
  line-height: 21px;
}

.detaile-banner1 .detaile-main-left-2 {
  position: absolute;
  top: 174px;
  left: 60px;
}

.detaile-banner1 .detaile-main-left-3 {
  position: absolute;
  top: 303px;
  left: 60px;
}

/**板块3  第二幅banner图**/
.detaile-banner2 {
  width: 1290px;
  height: 506px;
  background: url('./asset/detaile/banner2.png');
  background-size: 100% 100%;
  position: absolute;
}

.detaile-main2-left-1 {
  position: absolute;
  top: 173px;
  left: 60px;
}

.detaile-main2-left-2 {
  position: absolute;
  top: 304px;
  left: 60px;
}

.detaile-main2-right-1 {
  position: absolute;
  top: 197px;
  right: 69px;
}

.detaile-main2-right-2 {
  position: absolute;
  top: 286px;
  right: 69px;
}

/**板块3  第三幅banner图**/
.detaile-banner3 {
  width: 1290px;
  height: 506px;
  background: url('./asset/detaile/banner3.png');
  background-size: 100% 100%;
  position: absolute;
}

.detaile-main3-left-1 {
  position: absolute;
  top: 142px;
  left: 60px;
}

.detaile-main3-left-2 {
  position: absolute;
  top: 304px;
  left: 60px;
}

.detaile-main3-right-1 {
  position: absolute;
  top: 128px;
  right: 69px;
}

.detaile-main3-right-2 {
  position: absolute;
  top: 286px;
  right: 69px;
}

.comprehensive-img {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  width: 710px;
  height: 66px;
  margin: 0 auto;
}

.main-title {
  font-size: 36px;
}

.tag {
  height: 50px;

  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 25px;
  padding: 15px 41px;
  color: #A2A2A2;
  font-size: 22px;
  line-height: 20px;
  transition: all .5s;
}

.tag-active {
  transition: all .5s;
  background: #F6BB07;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  color: #765429;
}

.tag-main span{
  display: block;
  width:62px;
  height:0px;
  border:1px solid rgba(232,232,232,1);
  margin:0 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.banner3 {
  width: 1290px;
  height: 506px;
}

.order-manage-btn {
  /* width: 390px;
  height: 58px;
  border-radius: 29px;
  color: #765429;
  font-size: 24px;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1)) !important;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border:1px solid rgba(255, 204, 12, 1); */
  width: 190px;
  height: 44px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #17191A;
}

.order-manage-btn:focus,
.order-manage-btn:hover {
  background: #F6BB07 !important;
  color: #765429;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
}
.order-manage-btn2{
  width: 568px;
  height: 68px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07) !important;
  border-radius: 34px;
  font-size: 26px;
  font-weight: 400;
  color: #17191A;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
}
.order-manage-btn2:focus,
.order-manage-btn2:hover {
  background: #F6BB07 !important;
  color: #17191A;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
}

.order-manage-banner {
  /* background: url('./asset/banner4.png'); */
  background-size: 100% 100%;
  margin-top: 80px;
}

.icon-main {
  background: url('./asset/icon/icon2.png');
  background-size: 100% 100%;
  width: 136px;
  height: 152px;
  margin-right: 17.5px;
  margin-left: 17.5px;
  transform: rotateY(0deg);
  transition: all 1s;
}

.icon-main:hover {
  transform: rotateY(360deg);
  transition: all 1s;
}

.icon-main-title {
  color: #fff;
  font-size: 18px;
}

.icon-main-img {
  width: 48px;
  height: 48px;
}

.user-btn {
  width: 210px;
  height: 50px;
  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 25px;
  font-size: 22px;
  color: #A2A2A2;
  line-height: 49px;
  transition: all .5s;
}

.user-btn-active {
  transition: all .5s;
  background: #F6BB07;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24) !important;
  color: #fff;
}

.service-title {
  margin-top: 50px;
  font-size: 24px;
  /* font-family: SourceHanSansCN-Regular; */
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 38px;
}

.service-code {
  width: 210px;
  height: 300px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  transition: all .5s;
}
.service-code:hover{
  transition: all .4s;
  box-shadow:0px 8px 30px 0px rgba(246,187,7,0.2);
}
.service-code-active {
  box-shadow: 0px 8px 30px 0px rgba(246, 187, 7, 0.2);
  transition: all .5s;
}

.service-code-img {
  width: 150px;
  height: 150px;
  margin-top: 30px;
  margin-left: auto;
  display: block;
  margin-right: auto;
}

.service-code-describe {
  margin-top: 20px;
  line-height: 32px;
  color: #707070;
  font-size: 20px;
}

.service-left-img {
  padding-right: 110px;
}

.customized-card {
  padding: 50px;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  margin: 15px;
}

.customized-card-span {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

.customized-card-p {
  width: 480px;
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  color: #333333;
  margin: 0;
  margin-top: 19px;
}

.customized-blate-4 {
  padding: 45px 0;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  width: 410px;
  margin: 15px;
}

.customized-blate-4-span {
  font-size: 22px;
  font-weight: bold;
  line-height: 60px;
}

.customized-blate3 {
  /* background: url('./asset/customized/blate3bg.png'); */
  padding: 80px 0;
  background-size: 100% 100%;
}

.customized-carousel {
  border-radius: 10px;
  padding: 51px 0;
  /* box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2); */
  background: #FFFFFF;
  border-radius: 10px;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #fff;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #fff;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
}

.information-page-header span {
  font-size: 20px;
  line-height: 40px;
  color: #707070;
  margin-left: 10px;
}

.information-card {
  padding: 30px;
  max-width: 930px;
  margin: 0 auto;
  transition: all .3s;
  border-bottom: 1px solid #e8e8e8;
}

.information-news-title {
  line-height: 40px;
  font-size: 28px;
  color: rgba(51, 51, 51, 1);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.information-news-main {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  min-height: 80px;
  -webkit-box-orient: vertical;
}

.information-card:hover {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer
}

.el-pager li.active {
  background: #F6BB07 !important;
  color: #fff !important;
  border-color: #F6BB07 !important;
}

.about-title {
  font-size: 36px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 3px;
}

.about-dec {
  font-size: 20px;
  color: #ffffff;
}

.about-right-title {
  font-size: 16px;
  opacity: 0.8;
  color: #ffffff;
}

.about-right-num {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
}

.about-right-dec {
  font-size: 14px;
  opacity: 0.4;
  color: #ffffff;
}

.about-plate-2 {
  background: #F8F8F8;
  padding: 105px 0;
}

.about-plate-2-title {
  font-size: 36px;
  color: #333333;
  line-height: 58px;
}

.about-plate-2-text-main {
  width: 617px;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 50px;
  color: #A2A2A2;
}

#yili-map {
  width: 550px;
  height: 550px
}

.about-form {
  width: 620px;
}

.form-input {
  color: #AEAFB4;
  font-size: 16px;
}

.form-btn {
  background: rgba(246, 187, 7, 1);
  border-radius: 20px;
  border: none;
}

.form-btn:hover,
.form-btn:focus {
  background: rgba(246, 187, 7, 1);
  border-radius: 20px;
  color: #765429;
  border: none;
}

.about-card {
  width: 460px;
  padding: 53px 0;
  border: 1px solid rgba(236, 236, 248, 1);
  transition: all .3s;
}

.about-card:hover {
  border: 1px solid rgba(236, 236, 248, 0);
  ;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  transition: all .3s;
}

/**newsDetail**/
.newsDetail-title {
  padding: 40px 0 40px 0;
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  color: rgba(53, 55, 61, 1);
}

.newsDetail-createTime {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.newsDetail-other-news {
  display: block;
  width: 50%;
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  /* font-family: MicrosoftYaHeiLight; */
  font-weight: 300;
  color: rgba(174, 175, 180, 1) !important;
  background: #fff;
}

.newsDetail-other-news:hover {
  background: #F5AD01;
  color: #fff !important;
  transition: all 0.4s;
}

/**shopping**/
.shopping-banner {
  background: url('./asset/shopping/banner.png');
  background-size: 100% 100%;
  height: 62vh;
}

.shopping-plate1-hline {
  width: 40px;
  height: 4px;
  background: rgba(246, 187, 7, 1);
  border-radius: 2px;
}

.shopping-plate1 {
  margin: 20px 0;
}

.shopping-plate1-title {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}

.shopping-plate1-dec {
  width: 186px;
  font-size: 18px;
  color: #707070;
  line-height: 29px;
}

.shopping-plate2-main {
  background: url('./asset/shopping/plate2Bg.png');
  background-size: 100% 100%;
  padding-top: 80px;
}

.shopping-plate2-block {
  margin-top: 50px;
  padding-bottom: 80px
}

.shopping-item {
  width: 982px;
  margin-top: 20px;
}

.shopping-item-img {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  transition: all 0.4s;
  transform: rotate(0deg);
}

.shopping-item-layout {
  width: 80px;
  margin-right: 30px;
  height: 80px;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1));
  border-radius: 10px;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  transform: rotate(0deg);
  transition: all 0.4s;
}

.shopping-item-dec {
  width: 872px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding-left: 50px;
  font-size: 20px;
  color: #333333;
  transition: all 0.4s;
}

.shopping-item:hover .shopping-item-dec {
  font-weight: bold;
  transition: all 0.4s;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
}

.shopping-item:hover .shopping-item-layout {
  transition: all 0.4s;
  transform: rotate(50deg);
}

.shopping-item:hover .shopping-item-img {
  transition: all 0.4s;
  transform: rotate(-50deg);
}

.shopping-plate3-main {
  padding: 80px 0;
}

.shopping-plate3-line {
  width: 62px;
  border: 0.5px solid rgba(232, 232, 232, 1);
  margin-right: 15px;
  margin-left: 15px;
}

.shopping-plate3-img {
  width: 1290px;
  height: 506px;
}

.shopping-plate4 {
  /* background: url('./asset/banner4.png'); */
  background-size: 100% 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}

.plate4-icon {
  width: 72px;
  height: 72px;
  margin-bottom: 15px;
}

.plate-4-icon-box {
  width: 300px;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  margin: 15px;
}

.shopping-btn {
  width: 159px;
  height: 50px;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1)) !important;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24) !important;
  border-radius: 25px !important;
}

.shopping-btn:hover {
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1)) !important;
  border-color: #FFCC0C;
  color: #fff;
}

.shopping-plate5 {
  padding-top: 80px;
}



.caselist-main {
  padding-top: 80px;
}

.caselist-plate2-item {
  width: 410px;
  height: 192px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 10px;
  margin: 15px;
  padding: 0 50px;
  transition: all 0.4s;
}

.caselist-plate2-item h4 {
  font-weight: bold;
  color: rgba(246, 187, 7, 1);
  font-size: 44px;
}

.item-box span {
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
}

.item-box img:nth-child(1) {
  width: 30px;
  height: 30px;
}

.plate1-right-bottom-icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 53px;
  height: 53px;
  transition: all 0.4s;
  opacity: 0;
}

.caselist-plate2-item:hover {
  box-shadow: 0px 8px 30px 0px rgba(246, 187, 7, 0.2);
  transition: all 0.4s;
}

.caselist-plate2-item:hover .plate1-right-bottom-icon {
  opacity: 1;
  transition: all 0.4s;
}

.caselist-plate2 {
  background: rgba(248, 248, 248, 1);
  padding: 80px 0;
  position: relative;
}
.caselist-plate-set::before{
  content:'';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color:rgba(0,0,0,0.5);
}

.plate2-item-main {
  overflow: hidden;
  width: 410px;
  height: 410px;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.plate2-item-main span {
  width: 260px;
  height: 58px;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1));
  border-radius: 20px 20px 0px 0px;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 1);
}

.plate2-item-dec {
  padding: 30px 0;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 10px 10px;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  line-height: 32px;
  min-height: 286px;
  position: relative;
}

.plate2-item-dec::after {
  content: "";
  width: 60px;
  height: 4px;
  background: rgba(232, 232, 232, 1);
  border-radius: 2px;
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
}

.plate2-item-box {
  margin: 15px;
  cursor: pointer
}

.plate2-item-img {
  width: 410px;
  height: 410px;
  transform: scale(1);
  transition: all 0.4s;
}

.plate2-item-box:hover .plate2-item-img {
  transform: scale(1.2);
  transition: all 0.4s;
}

.caselist-plate3 {
  padding: 80px 0;
}

.caselist-plate4-item {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-row-gap: 89px;
  grid-column-gap: 180px;
}
.caselist-plate4-content{
  overflow: hidden;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  position: relative;
  height: 314px;
}
.caselist-plate4-item .caselist-plate4-content:hover{
  transition: all .4s;
  box-shadow: 0 8px 30px 0 rgba(246,187,7,.2);
}
.content-bottom > span{
  transition: all .4s;
}
.caselist-plate4-item .caselist-plate4-content:hover .content-bottom > span{
  color: #fff;
  transition: all .4s;
}

.caselist-plate4-item .content-top{
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0,0.7);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .4s;
}
.content-top > img{
  opacity: 1;
  transition: all .4s;
}
.caselist-plate4-item .caselist-plate4-content:hover .content-top > img{
  opacity: 0;
  transition: all .4s;
}
.caselist-plate4-item .caselist-plate4-content:hover .content-top .blate5-list-maks{
  transition: all .4s;
  background: rgba(246,187,7,.5);
}
.caselist-plate4-item .content-bottom .city{
  width: 140px;
  height: 50px;
  background: #111112;
  box-shadow: 0px 12px 12px 0px rgba(17, 17, 18, 0.24);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #FFFFFF;
  text-align: center;
  line-height: 50px;
  z-index: 11;
  position: absolute;
  left: 0;
  right: 0;
  top: -25px;
  transition: all .4s;
  margin: auto;
}
.caselist-plate4-item .content-bottom .content-code{
  width: 300px;
  height: 232px;
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .4s;
  z-index: 10;
  opacity: 0;
}
.caselist-plate4-item .caselist-plate4-content:hover .content-code{
  position: relative;
  opacity: 1;
  transition: all .4s;
  z-index: 10;
}
.caselist-plate4-item .caselist-plate4-content:hover .city{
  transition: all .4s;
  background: #f6bb07;
  box-shadow: 0 6px 6px 0 rgba(246,187,7,.24);
  color: #765429;
}
.caselist-plate4-item .content-bottom{
  width: 100%;
  height: 114px;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  border-radius: 0px 0px 10px 10px;
  position: relative;
  transition: all .4s;
}
.caselist-plate4-item .caselist-plate4-content:hover .content-bottom{
  height: 232px;
  transition: all .4s;
}
.caselist-plate4-item .content-bottom > span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  line-height: 114px;
  transition: all .5s;
}

.caselist-plate4-item img {
  width: 100px;
  height: 100px;
}

.main-5-title {
  font-size: 36px;
  margin-top: 40px;
  font-weight: 500;
}

.main-5-desc {
  font-size: 24px;
  color: #333333;
  margin: 0;
}






/* 改版后的样式 */
.bannerA-top-title{
  width: 783px;
  height: 77px;
  display: flex;
  box-shadow:0px 12px 12px 0px rgba(246,187,7,0.24);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.bannerA-top-title-middle{
  flex: 1;
  color: #F6BB07;
  letter-spacing:5px;
  height: 100%;
  font-weight: 600;
  font-size:36px;
  text-align: center;
  line-height: 77px;
  background:linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1));
}
.bannerA-top-title span{
  display: block;
  width: 45px;
  height: 77px;
}
.bannerA-top-title-left{
  border: 38px solid transparent;
  border-right-color: rgba(17,17,18,1);
}
.bannerA-top-title-right{
  border: 38px solid transparent;
  border-left-color: rgba(51,51,51,1);
}

.banner-btna {
  margin-top: 177px;
  width: 210px;
  height: 58px;
  border-radius: 29px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  border: 1px solid #765429;
}

.banner-btna:hover,
.banner-btna:focus {
  background:linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1))!important;
  color: #FAC209;
  border: 1px solid rgba(17,17,18,1);
}


.banner-btnb {
  margin-top: 177px;
  width: 210px;
  height: 58px;
  border-radius: 29px;
  font-size: 24px;
  font-weight: 400;
  color: #765429;
  background:linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1))!important;
  border: 1px solid #765429;
}

.banner-btnb:hover,
.banner-btnb:focus {
  background:linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1))!important;
  color: #FAC209;
  border: 1px solid rgba(17,17,18,1);
}


.bannerA-service{
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  letter-spacing:3px;
  line-height: 40px;
  padding:0 126px;
  color: #333333;
  border-radius: 20px;
  background:rgba(255,255,255,0.2);
  border:1px solid rgba(255, 255, 255, 0.4);
}

.bannar-icon-box{
  display: flex;
  width: 460px;
  height: 100px;
  justify-content: space-between;
  margin-top: 30px;
}
.bannar-icon-list{
  width: 98px;
  height: 98px;
  border:1px solid rgba(51,51,51,1);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding:20px 10px;
}
.bannar-icon-list img{
  margin-bottom: 5px;
}
.bannar-text-box{
  display: flex;
  width: 538px;
  justify-content: space-between;
  height: 36px;
  margin-top: 30px;
}

.bannar-text-box span{
  display: block;
  padding: 3px 10px;
  color: #FAC309;
  background-color: #333333;
  border-radius: 6px;
  box-sizing: border-box;
}
.more-btn{
  transition: all 0.4s;
  border:1px solid #333333;
  border-radius: 25px;
  height: 50px;
  line-height: 48px;
  box-sizing: border-box;
  padding:0 23px;
  color: #333333;
  font-size: 22px;
  width: 210px;
  margin-top: 75px;
  cursor:pointer;
}
.more-btn a{
  transition: all 0.4s;
  color: #333333 !important;
}
.bannar-text-boxA{
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-top: 15px;
  width: 640px;
}
.bannar-text-boxA span{
  display: block;
  padding: 3px 10px;
  color: #FAC309;
  background-color: #333333;
  border-radius: 6px;
  box-sizing: border-box;
}
.bannar-text-boxA-type{
  padding-left: 0;
}
.bannar-text-boxA-type li{
  list-style:none;
  letter-spacing: 5px;
  line-height: 36px;
}
.bannar-text-boxB{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 36px;
  margin-top: 20px;
  width: 475px;
}
.bannar-text-boxB span{
  margin-bottom: 10px;
  display: block;
  padding: 3px 10px;
  color: #FAC309;
  background-color: #333333;
  border-radius: 6px;
  box-sizing: border-box;
}
.more-btn:hover{
  margin-top: 75px;
  
  transition: all 0.4s;
  background-color: #FFCC0C;
  border:1px solid #FFCC0C;
  box-shadow:0px 12px 12px 0px rgba(246,187,7,0.24);
}
.more-btn:hover a{
  color: #765429 !important;
}

.system-box-bigImg{
  width: 160px;
  height: 160px;
  overflow: hidden;
}
.system-box-bigImg img{
  width: 160px;
  height: 160px;
  transform: scale(1);
  transition: all 0.4s;
}
.system-box:hover .system-box-bigImg img{
  transition: all .4s;
  transform: scale(1.5);
}
.system-title{
  transition: all 0.4s;
  font-size:24px;
  color:#333333;
  font-weight:bold;
}
.system-title-icon{
  width: 24px;
  height: 24px;
  margin-left: 7px;
  margin-top:3px;
  transition: all .4s;
  background-image: url('../src/asset/homeIcon/ic_life.png');
  background-position: 0,0;
  background-size: 100%;
}
.system-title-icon2{
  width: 24px;
  height: 24px;
  margin-left: 7px;
  margin-top:3px;
  transition: all .4s;
  background-image: url('../src/asset/homeIcon/ic_delivery.png');
  background-position: 0,0;
  background-size: 100%;
}
.system-box:hover .system-title-icon{
  width: 27px;
  height: 27px;
  margin-left: 7px;
  margin-top:0px;
  transition: all .4s;
  background-image: url('../src/asset/homeIcon/ic_life_hover.png');
}
.system-box:hover .system-title-icon2{
  width: 27px;
  height: 27px;
  margin-left: 7px;
  margin-top:0px;
  transition: all .4s;
  
  background-image: url('../src/asset/homeIcon/ic_delivery_hover.png');
}
.system-content{
  display: block;
  text-align: left;
  transition: all 0.4s;
  line-height: 2;
  color: #707070;
  font-size:16px;
}
.system-box:hover .more-details{
  transition: all 0.4s;
  border:2px solid #17191A;
  margin-bottom: 20px;
}
.more-details:hover{
  transition: all 0.4s;
  background-color: #FFCC0C !important;
  border:2px solid #FFCC0C !important;
  cursor: pointer;
  box-shadow:0px 12px 12px 0px rgba(246,187,7,0.24);
}
.more-details:hover a{
  transition: all 0.4s;
  color: #765429 !important;
}
.blate4-content ul{
  float: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blate4-content ul li{
  list-style-type:none;
  width: 1215px;
  height: 220px;
  display: flex;
  border:1px solid rgba(231, 231, 231, 1);
  align-items: center;
  padding:0 30px;
  justify-content: space-between;
  border-radius: 20px;
}

.blate4-content ul li:hover{
  transition: all 0.4s;
  box-shadow:0px 20px 20px 0px rgba(161,159,159,0.3);
}
.blate4-content-title{
  height: 120px;
}
.blate5-list-box ul{
  width: 1290px;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}
.blate5-list-box ul li{
  
  list-style-type:none;
  width: 300px;
  height: 314px;
  border-radius: 10px;
  box-shadow:0px 8px 30px 0px rgba(162,162,162,0.2);
  overflow: hidden;
}
.blate5-list-top{
  transition: all 0.4s;
  width: 300px;
  height: 200px;
  
  background-repeat:no-repeat;
  position: relative;
}
.blate5-list-maks{
  transition: all 0.4s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:rgba(0,0,0,0.5);
}
.blate5-list-maks img{
  transition: all 0.4s;
  opacity: 1;
}
.blate5-list-number{
  transition: all 0.4s;
  position: absolute;
  top: 175px;
  left: 80px;
  width:140px;
  height:50px;
  background:rgba(17,17,18,1);
  box-shadow:0px 12px 12px 0px rgba(17,17,18,0.24);
  border-radius:10px;
  color: #FFFFFF;
  font-size: 28px;
  /* font-style:italic; */
  font-weight:400;
  text-align: center;
  line-height: 1.8;
}
.blate5-list-bottom{
  transition: all 0.4s;
  height: 114px;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  position: relative;
}
.blate5-list-hidden{
  transition: all 0.4s;
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
  opacity: 0;
  
}
.blate5-list-box ul li:hover{
  transition: all 0.4s;
  box-shadow:0px 8px 30px 0px rgba(246,187,7,0.2);
}
.blate5-list-box ul li:hover .blate5-list-top{
  transition: all 0.4s;
  height: 80px;
}
.blate5-list-box ul li:hover .blate5-list-bottom{
  transition: all 0.4s;
  height: 232px;
  color: #fff;
}
.blate5-list-box ul li:hover .blate5-list-maks{
  transition: all 0.4s;
  background:rgba(246,187,7,0.5)
}
.blate5-list-box ul li:hover .blate5-list-maks img{
  transition: all 0.4s;
  opacity: 0;
}
.blate5-list-box ul li:hover .blate5-list-hidden{
  transition: all 0.4s;
  opacity: 1; 
}
.blate5-list-box ul li:hover .blate5-list-number{
  transition: all 0.4s;
  top: 55px;
  background:rgba(246,187,7,1);
  box-shadow:0px 6px 6px 0px rgba(246,187,7,0.24);
  color: #765429;
}
.ant-anchor-wrapper:hover {
  transition: all .4s !important;
  background-color:rgba(246,187,7,1) !important;
  border:1px solid rgba(246,187,7,1)!important;
  
}
.ant-anchor-wrapper:hover .ant-anchor-link-title{
  transition: all .4s !important;
  color: #765328 !important;
}
.blate5-list-hidden-link:hover{
  transition: all .4s ;
  background-color:rgba(246,187,7,1)!important;
  border:1px solid rgba(246,187,7,1)!important;
  color: #765328 !important;
}
.blate5-qq{
  width:210px;
  height:50px;
  border:1px solid rgba(51,51,51,1);
  border-radius:25px;
  box-sizing: border-box;
  padding:0 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #333333;
  margin:0 auto;
  margin-bottom: 17px;
  cursor:pointer;
}
.blate5-qq img{
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
/* 产品介绍detaile */
.main1{
  position:relative;
  background-color: #F8F8F8;
}
.main1-title{
  width: 1290px;
  height: 190px;
  background:rgba(255,255,255,1);
  box-shadow:0px 8px 30px 0px rgba(162,162,162,0.2);
  top: -40px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-icon-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-2-left{
  float: right;
  width: 451px;
  position: relative;
}
.main-2-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items:center;
  position: absolute;
  top: 404px;
  left: -20px;
}
.main-2-right{
  height: 100%;
  float: left;
  width: 451px;
}
.perfect-system-box{
  width: 935px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
}
.perfect-system-list{
  width: 290px;
  height: 100%;
  position: relative;
  margin-bottom: 40px;
}
.perfect-system-list-active .perfect-system-border-img{
  transition: all .4s;
  opacity: 1;
}
.perfect-system-border-img{
  transition: all .4s;
  opacity: 0;
  position: absolute;
  top: 12px;
  left: 0;
}

.perfect-system-bottom{
  display: flex;
  box-sizing: border-box;
  padding:30px 30px 0 30px;
}
.perfect-system-bottom-left{
  margin-right: 16px;
  margin-top: 7px;
}


/* 产品介绍-外卖 TakeOutFood */

.blate3-blocka{
  width: 410px;
  height: 148px;
  background: rgba(255, 255, 255, 1);
  animation-name: fadeInUp;
  border:1px solid #E8E8E8;
}

.blate3-blocka-title{
  width: 224px;
  height: 80px;
}
.blate3-blocka-p {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.blate3-blocka-p2 {
  font-size:14px;
  color: #A1A0A0;
  margin-top: 6px;
}
.blate3-blocka:hover{
  transition: all 0.4s;
  box-shadow:0px 20px 20px 0px rgba(161,159,159,0.3);
}
.blate3-blocka:hover .isBounce {
  animation-name: bounce;
}
.logical-system-box{
  width: 305px;
  height: 200px;
  position: relative;
}
.logical-system-title{
  width: 305px;
  height: 200px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  font-size:24px;
  color: #333333;
  border: 1px solid #E8E8E8;
}
.logical-system-box span{
  display: block;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 20px solid transparent;
  position: absolute;
  right: -20px;
  opacity: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.logical-system-box-active .logical-system-title{
  transition: all .4s;
  background: #FAC209;
  font-weight: 900;
  border: none;
}
.logical-system-box-active span{
  transition: all .4s;
  opacity: 1;
  border-left: 20px solid #FAC209;
}
.logical-system-right{
  flex: 1;
  height: 555px;
  display: block;
  padding-left:105px;
}
.logical-system-img-box{
  position: relative;
}

.logical-system-img{
  transition: all 0.4s;
  position: absolute;
  opacity: 0;
  top:158px;
}
.logical-system-code{
  flex-direction: column;
  position: absolute;
  right: 72px;
  top: 349px;
}

.logical-system-box-active2 .logical-system-title{
  transition: all .4s;
  background: #ffffff;
  font-weight: 900;
  border: none;
}
.logical-system-box-active2 span{
  transition: all .4s;
  opacity: 1;
  z-index: 1;
  border-left: 20px solid #ffffff;
}

.logical-system-right2{
  flex: 1;
  height: 100%;
  display: block;
  padding-left:105px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  position: relative;
}
.logical-system-right2-box{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.system-code-div{
  height: 176px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  padding:20px;
  border-radius: 10px;
  align-items: center;
  margin-top: 19px;
}
.logical-system-btn{
  width: 195px;
  height: 44px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #17191A;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 173px;
  top: 427px;
  cursor: pointer;
  transition: all .2s;
}
.logical-system-btn:hover{
  background-color: #FFCC0C;
  border:2px solid #FFCC0C;
  box-shadow:0px 12px 12px 0px rgba(246,187,7,0.24);
}
.system-code-line{
  width: 1px;
  height: 116px;
  background: #E8E8E8;
  margin: 0 16px 0 10px;
}
.system-code-right{
  flex-direction: column;
  align-items: center;
}
.logical-system-img-active{
  transition: all 0.4s;
  opacity: 1;
  z-index: 99;
}
.logical-system-title1{
  transition: all .4s;
  position: absolute;
  top: 59px;
  left: 0;
  right: 0;
  font-size: 30px;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}
.logical-system-title2{
  transition: all .4s;
  position: absolute;
  top: 109px;
  left: 0;
  right: 0;
  font-size: 20px;
  color: #707070;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}
.logical-system-title1-active{
  transition: all .4s;
  opacity: 1;
}
.logical-system-title2-active{
  transition: all .4s;
  opacity: 1;
}

.logical-system-title3{
  width: 305px;
  height: 200px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  font-size:24px;
  color: #333333;
  border: 1px solid #E8E8E8;
  opacity: 0.3;
}

.logical-system-box-active2 .logical-system-title3{
  transition: all .4s;
  opacity: 1;
}
.logical-system-title3 img{
  width: 70px;
  height: 70px;
}


.delivery-boxa {
  width: 223px;
  height: 275px;
  background: linear-gradient(0deg, rgba(248, 248, 248, 1), rgba(255, 255, 255, 1));
  border-radius: 10px;
  transition: box-shadow .5s;
  position: relative;
  margin: 0 10px;
}

.text-describea {
  transition: all .4s;
  width: 220px;
  margin-top: 79px;
  font-size: 14px;
  font-weight: 300;
  color: #707070;
}
.text-bottom-describea {
  width: 100px;
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  transition: all .3s;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.delivery-title{
  width: 140px;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  text-align: center;
  transition: all .5s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 96px;
  margin: 0 auto;
}

.delivery-boxa:hover {
  box-shadow: 0px 8px 30px 0px rgba(162, 162, 162, 0.2);
  transition: box-shadow .5s;
  background: #fff;
}

.delivery-boxa:hover .text-describea {
  color: #333333;
  margin-top: 30px;
  transition: all .3s;
}

.delivery-boxa:hover .delivery-title {
  width: 140px;
  color: #333;
  background: linear-gradient(90deg, rgba(255, 204, 12, 1), rgba(246, 187, 7, 1));
  border-radius: 20px 20px 0px 0px;
  padding: 4px 0;
  bottom: 0px;
  transition:bottom 0.2s linear, background 1s linear;
}
.common-selection-box{
  width:410px;
  height:235px;
  background:rgba(255,255,255,1);
  box-shadow:0px 8px 30px 0px rgba(162,162,162,0.2);
  border-radius:10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding:30px 0;
  position: relative;
}
.common-selection-box-img{
  transition: all .4s;
  opacity: 1;
  margin-bottom: 20px;
}
.common-selection-box-logo{
  position: absolute;
  left: 0;
  right: 0;
  margin:0 auto;
  top: 30px;
  opacity: 0;
}
.common-selection-box-title{
  font-size:24px;
  color:#333333;
  text-align:center;
  font-weight:400
}

.common-selection-box-title1{
  font-size:20px;
  color:#A2A0A0;
  text-align:center;
}
.common-selection-box-btn{
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  margin:0 auto;
  bottom: -30px;
  width:306px;
  height:50px;
  background:linear-gradient(90deg,rgba(255,204,12,1),rgba(246,187,7,1));
  box-shadow:0px 12px 12px 0px rgba(246,187,7,0.24);
  border-radius:25px;
  text-align: center;
  line-height: 50px;
  font-size:22px;
  font-weight:400;
  color:rgba(118,84,41,1);
}
.common-selection-box:hover .common-selection-box-img{
  transition: all .4s;
  opacity: 0;
}
.common-selection-box:hover .common-selection-box-logo{
  transition: all .4s;
  opacity: 1;
}
.common-selection-box:hover .common-selection-box-title{
  transition: all .4s;
  color: #fff;
}
.common-selection-box:hover .common-selection-box-title1{
  transition: all .4s;
  color: #fff;
}
.common-selection-box:hover .common-selection-box-btn{
  transition: all .4s;
  bottom: 35px;
  opacity: 1;
}
.main-5-desc-btn{
  transition: all .4s;
  margin-top: 25px;
  width: 210px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  color: #333333;
  border-radius: 25px;
  border:1px solid #333333;
  font-size: 20px;
  cursor: pointer;
}
.main-5-desc-btn:hover{
  transition: all .4s;
  color: #765328;
  background-color: rgba(255,204,12,1);
  border: 1px solid rgba(255,204,12,1);
}
.service-code-btn{
  display: none;
}
.service-diaodu-btn{
  transition: all .4s;
  margin-top: 25px;
  padding:0 20px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  color: #333333;
  border-radius: 25px;
  border: 1px solid #333333;
  font-size: 20px;
  cursor: pointer;
}
.service-diaodu-btn:hover{
  transition: all .4s;
  color: #765328;
  background-color: rgba(255,204,12,1);
  border: 1px solid rgba(255,204,12,1);
}



.loading-box{
  position: absolute;
  top: -44px;
  left: 0;
  width: 100%;
  height: 109%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-box-none{
  display: none;
}
.ant-message{
  z-index: 9999 !important;
}

.home-module5-box{
  width: 458px;
  height: 458px;
  box-shadow: 0px 10px 30px 0px rgba(115, 127, 128, 0.2);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.home-module5-box::before{
  content: '';
  position: absolute;
  width: 458px;
  height: 332px;
  background: #000000;
  opacity: 0.7;
  border-radius: 20px 20px 0px 0px;
  top: 0;
  transition: all 0.4s;
}
.home-module5-box:hover::before{
  opacity: 0;
} 
.home-module5-box > img:nth-of-type(2){
  position: absolute;
  top: 0;
}
.home-module5-box-bottom{
  width: 100%;
  flex: 1;
  margin-top:-15px;
  background: #FFFFFF;
  border-radius: 20px;
  padding:40px 70px 30px 60px;
  position: relative;
  
}
.home-module5-box-bottom-title{
  width: 260px;
  height: 58px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  border-radius: 20px 20px 0px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #121213;
  position: absolute;
  text-align: center;
  line-height: 58px;
  top: -58px;
  left: 0;
  right: 0;
  margin: auto;
}

.home-module5-box-bottom p{
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing:1px;
}
.home-module5-box-bottom-line{
  width: 60px;
  height: 4px;
  background: #E8E8E8;
  border-radius: 2px;
  margin: 0 auto;
}
.home-module6-box{
  width: 705px;
  height: 176px;
  overflow: hidden;
  background: #FFFFFF;
  display: flex;
  padding:25px 30px;
  overflow: hidden;
  box-sizing: border-box;
  transition:all 0.4s;
  border-radius: 20px;
  margin-top: 10px;
}
.home-module6-box:hover{
  transition:all 0.4s;
  box-shadow: 0px 10px 30px 0px rgba(115, 127, 128, 0.2);
}
.home-module6-box-right{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-module6-box-right-title{
  width: 450px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.home-module6-box-right-content{
  width: 450px;
  height: 42px;
  text-overflow:ellipsis;
  color: #707070;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
  overflow: hidden;
}

.detaile-module3-box{
  width: 360px;
  height:726px;
  padding: 30px 0 30px 30px;
  transition: all 0.4s;
  box-sizing: border-box;
}
.detaile-module3-box-content{
  width: 100%;
  height: 100%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detaile-module3-box-content-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 170px;
  box-sizing: border-box;
  border-radius:20px;
  border:1px solid rgba(0, 0, 0, 0) ;
  background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
  transition: all 0.4s;
}
.detaile-module3-box-content-top-title{
  width: 140px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: 24px;
  border-radius: 0px 0px 20px 20px;
  font-size: 24px;
  font-weight: bold;
  background: #fff;
  margin-bottom: 11px;
  transition: all 0.4s;
}
.detaile-module3-box-content-bottom{
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}
.detaile-module3-bottom-item{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  font-style: 16px;
  color: #121213;
}
.detaile-module3-bottom-item img{
  width: 80px;
  height: 80px;
}

.detaile-module3-box:hover{
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  transition: all 0.4s;
}
.detaile-module3-box:hover .detaile-module3-box-content-top{
  background: linear-gradient(0deg, #FFFFFF , #FFFFFF);
  border:1px solid #E8E8E8;
  transition: all 0.4s;
}
.detaile-module3-box:hover .detaile-module3-box-content-top-title{
  margin-top: 0px;
  margin-bottom: 35px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  transition: all 0.4s;
}

.detaile-module5-box{
  width: 1440px;
  height: 360px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.detaile-module5-box-item{
  width: 338px;
  height: 100%;
  background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:40px 30px 0 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px 0px rgba(255,255,255,1);
  transition: all 0.4s;
}
.detaile-module5-box-item-top{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #E8E8E8;
  position: relative;
}
.detaile-module5-box-item-top-radius{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin:auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #333333;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detaile-module5-box-item:hover .detaile-module5-box-item-top-radius{
  background: #F6BB07;
  transition: all 0.4s;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
}
.detaile-module5-box-item:hover{
  transition: all 0.4s;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  background: linear-gradient(0deg,  #FFFFFF, #FFFFFF);
}


.border-right{
  border-right: 1px solid #E8E8E8;
  
}


/* 清除antd锚点默认样式 */
.ant-anchor-ink{
  display: none !important;
}
.ant-anchor-link-title-active{
  color: #333333;
  font-size: 22px;
}
.ant-anchor-link{
  padding: 0;
}
.ant-anchor{
  color: #333333 !important;
  font-size: 20px !important;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #333333 !important;
  font-size: 20px !important;
}

.logical-system-swiper{
  width:845px;
}
/* 修改loading默认样式 */
.ant-spin-dot-item{
  background-color:rgba(255,204,12,1) !important;
}

.more-button{
  width: 156px;
  height: 36px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 19px;
  transition:  all .4s;
}
.more-button:hover{
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #FFCC0C;
}
.more-button span{
  font-size: 14px;
  font-weight: 400;
  color: #17191A;
}
.more-button span:nth-of-type(2){
  font-weight: 700;
}
.industry-Case{
  width: 252px;
  height: 44px;
  border: 2px solid #FFFFFF;
  border-radius: 22px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition:  all .4s;
  margin: auto;
  margin-top: 60px;
}
.industry-Case span{
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
}
.industry-Case span:nth-of-type(2){
  font-weight: 700;
}
.industry-Case:hover{
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #ffca0c00;
}
.fw-600{
  font-weight: 600;
}
.jsbet{justify-content: space-between}
.jscen{ justify-content:center}
.flex-start{justify-content:flex-start}
.flex-end{justify-content:flex-end}
.jsaround{justify-content: space-around}
.align-start{align-items:flex-start}
.align-cen{align-items:center}
.align-end{align-items: flex-end;}
.wrap{flex-wrap: wrap}
.row-reverse{flex-direction: row-reverse}
.column-reverse{flex-flow: column-reverse}
.flex1{flex: 1}
.posre{position: relative}
.posab{position: absolute}
.posfix{position: fixed}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mb30{
  margin-bottom: 30px;
}
.opacity0{
  opacity: 0;
}
.widthAuto{
  width: auto;
}
.ant-btn-primary {
  background-color:#f6bb07;
  border-color:#f6bb07;
}