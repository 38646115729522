.carousel-div {
  position: relative;
}
.carousel-div .carousel-box {
  width: 1400px;
  height: 670px;
  overflow: hidden;
  position: relative;
}
.carousel-div .carousel-box .carousel-itme {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  right: 0;
  box-shadow: 1px 10px 30px 0px rgba(116, 128, 128, 0.2);
  margin: auto;
  transition: all 0.3s ease;
  border-radius: 20px;
  height: 566px;
  width: 262px;
}
.carousel-div .carousel-box .carousel-itme img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.carousel-div .carousel-box .carousel-itme .carousel-but {
  width: 160px;
  height: 44px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #17191A;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 28px;
  cursor: pointer;
  transition: all 0.2s;
}
.carousel-div .carousel-box .carousel-itme .carousel-but:hover {
  background: #f6bb07;
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #17191a00;
}
.carousel-div .carousel-box .p0 {
  transform: translate3d(-842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .carousel-box .p1 {
  transform: translate3d(-560px, 0, 0) scale(0.8);
  z-index: 2;
}
.carousel-div .carousel-box .p2 {
  transform: translate3d(-298px, 0, 0) scale(0.9);
  z-index: 2;
}
.carousel-div .carousel-box .p3 {
  transform: translate3d(0, 0, 0) scale(1);
  z-index: 2;
}
.carousel-div .carousel-box .p3 .carousel-but {
  margin-top: 52px;
}
.carousel-div .carousel-box .p4 {
  transform: translate3d(298px, 0, 0) scale(0.9);
  z-index: 2;
}
.carousel-div .carousel-box .p5 {
  transform: translate3d(560px, 0, 0) scale(0.7);
  z-index: 2;
}
.carousel-div .carousel-box .p6 {
  transform: translate3d(842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .carousel-box .p7 {
  transform: translate3d(842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .carousel-box .p8 {
  transform: translate3d(842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .carousel-box .p9 {
  transform: translate3d(842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .carousel-box .p10 {
  transform: translate3d(842px, 0, 0) scale(0.7);
  opacity: 0;
}
.carousel-div .extend, .carousel-div .left-img, .carousel-div .right-img {
  position: absolute;
  top: -200px;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.carousel-div .right-img {
  right: 0;
}
.carousel-div .left-img {
  left: 0;
}

/* @keyframes dex {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */