.index-collect {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-collect .index-collect-title {
  font-size: 36px;
  color: #17191A;
  font-weight: bold;
}
.index-collect .index-collect-title .title-main {
  display: flex;
  justify-content: center;
}
.index-collect .index-collect-title .title-main .title-main-box {
  position: relative;
}
.index-collect .index-collect-title .title-main .title-main-box span {
  position: relative;
  z-index: 1;
}
.index-collect .index-collect-title .title-main .title-main-box .main-bg {
  position: absolute;
  height: 15px;
  width: 100%;
  opacity: 0.2;
  bottom: 6px;
  background: #F6BB07;
}
.index-collect .index-collect-title .input-box {
  display: flex;
  margin-top: 40px;
}
.index-collect .index-collect-title .input-box input {
  background: none;
  outline: none;
  border: none;
  font-size: 20px;
  color: #C0CBCC;
}
.index-collect .index-collect-title .input-box .input-box-left {
  width: 498px;
  height: 60px;
  display: flex;
  align-items: center;
  background: #F8F8F8;
  border-radius: 6px;
  padding: 30px 20px;
  margin-right: 36px;
}
.index-collect .index-collect-title .input-box .input-box-left span {
  font-size: 20px;
  font-weight: bold;
  color: #454A4C;
  margin-right: 16px;
}
.index-collect .index-collect-title .input-box .input-box-right {
  width: 498px;
  height: 60px;
  display: flex;
  align-items: center;
  background: #F8F8F8;
  border-radius: 6px;
  padding: 30px 20px;
}
.index-collect .index-collect-title .input-box .input-box-right span {
  font-size: 20px;
  font-weight: bold;
  color: #454A4C;
  margin-right: 16px;
}
.index-collect .submit-btn {
  width: 568px;
  height: 68px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #17191A;
  margin-top: 24px;
  font-weight: bold;
}

.carousel-box {
  width: 336px;
  height: 40px;
  background: rgba(24, 135, 230, 0.08);
  border-radius: 20px;
  margin-top: 30px;
  overflow: hidden;
}
.carousel-box .scrolList-box {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  flex-direction: column;
  height: 410px;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrollToBottom;
}
.carousel-box .scrolList-box .scrolList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.home-tags-top {
  width: 1440px;
  display: flex;
}
.home-tags-top .home-tags {
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  height: 181px;
  position: relative;
}
.home-tags-top .home-tags img {
  width: 120px;
  height: 120px;
  margin-bottom: 6px;
}
.home-tags-top .home-tags .home-tag-title1 {
  font-size: 22px;
  color: #121213;
  margin-bottom: 0;
  line-height: 1;
  transition: all 0.4s;
}
.home-tags-top .home-tags .home-tag-title2 {
  font-size: 26px;
  color: #121213;
  margin-bottom: 0;
  transition: all 0.4s;
  font-weight: bold;
  color: #121213;
  font-size: 53px;
  opacity: 0.08;
  line-height: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  text-align: center;
}
.home-tags-top .home-tags span {
  width: 80px;
  height: 8px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border-radius: 4px;
  position: absolute;
  bottom: 4px;
  opacity: 0;
  transition: all 0.4s;
}
.home-tags-top .home-tags-active .home-tag-title1 {
  font-size: 24px;
  color: #121213;
  font-weight: bold;
  transition: all 0.4s;
}
.home-tags-top .home-tags-active span {
  opacity: 1 !important;
  transition: all 0.4s;
}

.home-tags-bottom1 {
  width: 1440px;
  height: 700px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-top: 60px;
  margin-bottom: 90px;
  position: relative;
  padding: 60px 75px;
  display: flex;
  justify-content: space-between;
}
.home-tags-bottom1 .home-tags-bottom1-left {
  padding-top: 6px;
}
.home-tags-bottom1 .home-tags-bottom1-left h1 {
  font-weight: bold;
  color: #121213;
  margin-bottom: 15px;
  line-height: 1;
}
.home-tags-bottom1 .home-tags-bottom1-left .home-tags-bottom1-left-content {
  width: 630px;
  padding: 28px 31px 30px 28px;
  background: #FFFCF5;
}
.home-tags-bottom1 .home-tags-bottom1-left .home-tags-bottom1-left-content p {
  font-size: 20px;
  color: #765429;
  line-height: 38px;
  margin-bottom: 0;
}
.home-tags-bottom1 .home-tags-bottom1-left .home-tags-bottom1-left-list {
  margin-top: 50px;
}
.home-tags-bottom1 .home-tags-bottom1-left .home-tags-bottom1-left-list img {
  height: 80px;
  width: 80px;
  margin-bottom: 16px;
}
.home-tags-bottom1 .home-tags-bottom1-left .home-tags-bottom1-left-list span {
  font-size: 20px;
  color: 20px;
  line-height: 1;
  font-weight: bold;
}

.home-tags-bottom1-right {
  width: 552px;
  height: 552px;
  border-radius: 10px;
}

.home-tags-button {
  width: 220px;
  height: 60px;
  background: #FFFFFF;
  border: 2px solid #17191A;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #17191A;
}

.home-tags-button:hover {
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
  transition: all 0.4s;
}

.home-tags-more-button {
  cursor: pointer;
  width: 220px;
  height: 60px;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
  border: 2px solid #F6BB07;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #17191A;
}

.O2O-system-list {
  display: flex;
  justify-content: space-between;
  width: 460px;
  height: 212px;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px 0px rgba(115, 127, 128, 0.2);
  border-radius: 20px;
  padding: 40px 37px 37px 40px;
  margin-bottom: 30px;
}
.O2O-system-list img {
  width: 80px;
  height: 80px;
}

@keyframes scrollToBottom {
  0% {
    -webkit-transform: translate3d(0, 0%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -50%, 0);
  }
}
.module7_columns {
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
}

#module7_columns_ul {
  list-style: none;
  width: 3200px;
  left: 0;
  top: 0;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrollToRight;
}

#module7_columns_ul2 {
  list-style: none;
  width: 3200px;
  left: 0;
  top: 93px;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  animation-duration: 36s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrollToRight;
}

#module7_columns_ul3 {
  list-style: none;
  width: 3200px;
  left: 0;
  top: 186px;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrollToRight;
}

.module7_columns .maskA {
  position: absolute;
  width: 220px;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #000000 0%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.module7_columns .maskB {
  position: absolute;
  width: 220px;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(-90deg, #000000 0%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.module8_columns {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 420px;
  overflow: hidden;
}

#module8_columns_ul {
  list-style: none;
  width: 17750px;
  left: 0;
  top: 0;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrollToRight;
}

#module8_columns_ul:hover {
  animation-play-state: paused;
}

.module8_columns .maskA {
  position: absolute;
  width: 220px;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #000000 0%, #F7F9FC 0%, rgba(247, 249, 252, 0) 100%);
}

.module8_columns .maskB {
  position: absolute;
  width: 220px;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(-90deg, #000000 0%, #F7F9FC 0%, rgba(247, 249, 252, 0) 100%);
}

@keyframes scrollToRight {
  0% {
    -webkit-transform: translate3d(0%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
  }
}