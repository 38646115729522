.title-main-box {
  position: relative;
}
.title-main-box span {
  position: relative;
  z-index: 1;
  font-size: 28px;
  font-weight: bold;
  color: #121213;
}
.title-main-box .main-bg {
  position: absolute;
  height: 15px;
  width: 100%;
  opacity: 0.3;
  bottom: 6px;
  background: #F6BB07;
}

.shadowBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 30px 30px 0 30px;
  text-align: center;
}

.system-oto-modulesF p {
  line-height: 25.5px;
  font-size: 16px;
  color: #707070;
}
.system-oto-modulesF .system-oto-modulesF-title {
  height: 50px;
  border-radius: 25px;
  padding: 0 19px 0 19px;
  margin-bottom: 19px;
  font-size: 24px;
  font-weight: bold;
}