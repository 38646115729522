//  bootstrap
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "../node_modules/bootstrap/scss/utilities/align";
@import "../node_modules/bootstrap/scss/utilities/background";
@import "../node_modules/bootstrap/scss/utilities/borders";
@import "../node_modules/bootstrap/scss/utilities/clearfix";
@import "../node_modules/bootstrap/scss/utilities/display";
@import "../node_modules/bootstrap/scss/utilities/flex";
@import "../node_modules/bootstrap/scss/utilities/float";
@import "../node_modules/bootstrap/scss/utilities/shadows";
@import "../node_modules/bootstrap/scss/utilities/sizing";
@import "../node_modules/bootstrap/scss/utilities/spacing";
@import "../node_modules/bootstrap/scss/utilities/text";




// @media screen and (max-width: 767px) {
//   @keyframes fadeInDown {
//     from {
//       opacity: 0;
//       transform: translate3d(0, -100%, 0);
//     }
//     to {
//       opacity: 1;
//       transform: translate3d(0, 0, 0);
//     }
//   }
//   .fadeInDown {
//     animation-name: fadeInDown;
//   }
//   .hiddenNav {
//     display: none !important;
//   } 
//   .container {
//     display: block !important;
//   }
//   .menu-main .menu {
//     display: block;
//   }
//   .menu .menu-li {
//     display: block !important;
//     width: 100%;
//     line-height: 37px;
//     border-radius: 0;
//     padding-left: 20px;
//     margin: 0;
//   }
//   .container .icon {
//     display: block;
//     width: 16px;
//     height: 14px;
//     cursor: pointer;
//     position: absolute;
//     top: 24px;
//     right: 24px;
//     z-index: 100;
//   }
//   .container .icon em {
//     display: block;
//     width: 100%;
//     height: 2px;
//     background: #000;
//     margin-top: 4px;
//     -webkit-transition: opacity .3s cubic-bezier(.645, .045, .355, 1),-webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
//     transition: opacity .3s cubic-bezier(.645, .045, .355, 1),-webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
//     transition: transform .3s cubic-bezier(.645, .045, .355, 1),opacity .3s cubic-bezier(.645, .045, .355, 1);
//     transition: transform .3s cubic-bezier(.645, .045, .355, 1),opacity .3s cubic-bezier(.645, .045, .355, 1),-webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
//   }
//   .open .icon em:nth-child(1){
//     transform: translateY(6px) rotate(45deg);
//   }
//   .open .icon em:nth-child(2){
//     opacity: 0;
//   }
//   .open .icon em:nth-child(3){
//     transform: translateY(-6px) rotate(-45deg);
//   }
//   .menu-main .menu-li-btn {
//     display: block !important;
//     width: 100%;
//     line-height: 37px;
//     border-radius: 0;
//     border: 0px;
//     margin: 0;
//   }
//   #global-nav-openmenu .menu-li {
//     opacity: 0;
//   } 
//   #global-nav-openmenu .menu-li:nth-child(1) {
//     transform: translateY(-44px);
//     transition: opacity .3345s cubic-bezier(0.52, 0.16, 0.52, 0.84) .15s,transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s,-webkit-transform .4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) .108s;
//   }
//   #global-nav-openmenu .menu-li:nth-child(2) {
//     transform: translateY(-40px);
//     transition: opacity .28578s cubic-bezier(0.52, 0.16, 0.52, 0.84) .126s,transform .44757s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0924s,-webkit-transform .44757s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0924s;
//   }
//   #global-nav-openmenu .menu-li:nth-child(3) {
//     transform: translateY(-36px);
//     transition: opacity .25088s cubic-bezier(0.52, 0.16, 0.52, 0.84) .102s,transform .43341s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0768s,-webkit-transform .43341s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0768s;
//   }
//   #global-nav-openmenu .menu-li:nth-child(4) {
//     transform: translateY(-32px);
//     transition: opacity .22978s cubic-bezier(0.52, 0.16, 0.52, 0.84) .078s,transform .42443s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0612s,-webkit-transform .42443s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0612s;
//   }
//   #global-nav-openmenu .menu-li:nth-child(5) {
//     transform: translateY(-28px);
//     transition: opacity .22248s cubic-bezier(0.52, 0.16, 0.52, 0.84) .054s,transform .42063s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0456s,-webkit-transform .42063s cubic-bezier(0.52, 0.16, 0.52, 0.84) .0456s;
//   }
//   #global-nav-openmenu .menu-li:nth-child(6) {
//     transform: translateY(-24px);
//     transition: opacity .229s cubic-bezier(0.52, 0.16, 0.52, 0.84) .03s,transform .422s cubic-bezier(0.52, 0.16, 0.52, 0.84) .03s,-webkit-transform .422s cubic-bezier(0.52, 0.16, 0.52, 0.84) .03s;
//   }
//   #menustate:checked ~ #global-nav-openmenu, #menustate:target ~ #global-nav-openmenu {
//     height: 100%;
//     transition: height .56s cubic-bezier(0.52, 0.16, 0.24, 1);
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li, #menustate:target ~ #global-nav-openmenu .menu-li {
//     opacity: 1;
//     transform: none;
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(1), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(1){
//     transition: opacity .3091s cubic-bezier(0.32, 0.08, 0.24, 1) .03s,transform .3455s cubic-bezier(0.32, 0.08, 0.24, 1) .02s,-webkit-transform .3455s cubic-bezier(0.32, 0.08, 0.24, 1) .02s;
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(2), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(2){
//     transition: opacity .32498s cubic-bezier(0.32, 0.08, 0.24, 1) .054s,transform .36132s cubic-bezier(0.32, 0.08, 0.24, 1) .044s,-webkit-transform .36132s cubic-bezier(0.32, 0.08, 0.24, 1) .044s;  
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(3), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(3){
//     transition: opacity .33924s cubic-bezier(0.32, 0.08, 0.24, 1) .078s,transform .38348s cubic-bezier(0.32, 0.08, 0.24, 1) .068s,-webkit-transform .38348s cubic-bezier(0.32, 0.08, 0.24, 1) .068s;
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(4), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(4){
//     transition: opacity .3519s cubic-bezier(0.32, 0.08, 0.24, 1) .102s,transform .41196s cubic-bezier(0.32, 0.08, 0.24, 1) .092s,-webkit-transform .41196s cubic-bezier(0.32, 0.08, 0.24, 1) .092s;
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(5), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(5){
//     transition: opacity .36296s cubic-bezier(0.32, 0.08, 0.24, 1) .126s,transform .44676s cubic-bezier(0.32, 0.08, 0.24, 1) .116s,-webkit-transform .44676s cubic-bezier(0.32, 0.08, 0.24, 1) .116s;
//   }
//   #menustate:checked ~ #global-nav-openmenu .menu-li:nth-child(6), #menustate:target ~ #global-nav-openmenu .menu-li:nth-child(6){
//     transition: opacity .3724s cubic-bezier(0.32, 0.08, 0.24, 1) .15s,transform .4879s cubic-bezier(0.32, 0.08, 0.24, 1) .14s,-webkit-transform .4879s cubic-bezier(0.32, 0.08, 0.24, 1) .14s;
//   }
// }


.form-title {
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: bold;
  color: rgba(53, 55, 61, 1);
}
.form-contacts {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  margin-top: 50px;
}
.form-contacts input{
  outline: none;
  border: none;
}
.form-contacts label {
  width: 100px;
  color: #000;
}
.form-btn-OnTrial {
  width: 100%;
  background: #f6bb07;
  height: 64px;
  line-height: 64px;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  margin-top: 20px;
}
.form-btn-OnTrial:focus {
  border: none;
  outline: none;
}
.el-dialog__body {
  padding-top: 0 !important;
  padding-right: 65px !important;
  padding-left: 65px !important;
}
.ant-select-selection {
  outline: none !important;
  border: none !important;
  width: 160px !important;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-left: 0px !important;
}
.ant-select-selection:focus {
  box-shadow: none !important;
}
#global-nav-openmenu {
  height: 80px;
  transition: height .56s cubic-bezier(0.52, 0.16, 0.24, 1);
}

.global-nav {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  top: 0;
}

.global-main {
  padding-top: 80px;
}

.container {
  height: 100%;
  max-width: 1440px;
  position: relative;
  margin: auto;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: inline-block;
  position: relative;
}

.menu {
  list-style: none;
  display: flex;
}

.icon {
  display: none;
}

.menu-li {
  width: 120px;
  height: 80px;
  box-sizing: border-box;
  padding-top: 20px;
  color: #333333 !important;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-bottom:2px solid rgba(255, 255, 255, 0);
}
.menu-li-dec{
  transition: all .3s;
  font-size: 12px;
  font-weight: 300;
  color: #FFFFFF;
  opacity: 0.6;
}

.menu-li-btn {
  width: 120px;
  height: 40px;
  border-radius:20px;
  color: #765429;
  cursor: pointer;
  transition: background ease-in-out .3s;
   display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 75px;
  background: #f6bb07;
}

.menu-li-btn:hover {
  border: 1px solid #f6bb07;
  background: #f6bb07;
  color: #333333;
}
.menu-li-title{
  font-size: 16px;
  color: #FFFFFF;
}
.menu-li-active .menu-li-title{
  font-weight: bold;
  transition: all .3s;
}

.menu-li-black .menu-li-title{
  color: #121213;
  transition: all .3s;
}
.menu-li-black .menu-li-dec{
  color: #000000;
  opacity: 0.6;
  transition: all .3s;
}
.menu-li-active {
  transition: all .3s;
  color: #765429 !important;
  border-bottom:2px solid #f6bb07 !important;
}
.menu-li-active .menu-li-dec{
  transition: all .3s;
  color: #f6bb07 !important;
}

.menu-li:hover {
  color: #765429;
}

.qq-msg {
  width: 95px;
  height: 24px;
  background: rgba(255,255,255,1);
  border-radius: 12px;
}
.footer-hover{
  position: relative;
}
.footer-hover:hover>.footer-hover-img {
  display: block;
}
.footer-hover-img {
  position: absolute;
  top: 0px;
  left: 70px;
  width: 120px;
  height: 120px;
  display: none;
  z-index: 10;
}

.global-title-main {
  font-size:36px;
  color:rgba(51,51,51,1);
}
.global-title-img {
  position: relative;
  top: -26px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.global-title {
  font-size:36px;
  color:rgba(51,51,51,1);
  margin-bottom: 8px;
}
.global-small-title{
  font-size:20px;
  color:#707070;
  margin-top: 20px;
  width: 1000px;
  line-height: 30px;
}


.global-banner {
  // background: url('./asset/banner.png');
  background-size: 100% 100%;
  height: 62vh;
}
.global-banner-text {
  font-weight:bold;
  color:#333333;
  font-size:44px; 
}
.global-banner-span {
  color:rgba(118,84,41,1);
  font-weight:300;
  font-size:24px;
}
.global-banner-btn {
  margin-top: 177px !important;
  width:210px !important;
  height:58px !important;
  border-radius:29px !important;
  font-size:24px !important;
  font-weight:400 !important;
  color: #333333 !important;
  border: 1px solid #765429 !important;
}
.global-banner-btn:hover, .global-banner-btn:focus {
  background: linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1)) !important;
  color: #FAC209 !important;
  border: 1px solid #765429 !important;
}

@media (min-width: 768px){
  .containers {
    width: 750px;
  }
}
@media (min-width: 992px){
  .containers {
    width: 970px;
  }
}
@media (min-width: 1200px){
  .containers {
    width: 1170px;
  }
}

.html-box{
  line-height: 2!important;
}

.html-box img{
  width: 600px;
  height: auto;
  margin-bottom: 1em !important;
}

.containers {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.second-level {
  transition: all .4s;
  cursor: pointer;
  flex-direction: column;
  width: 260px;
  height: 60px;
  border-radius:6px;
  font-weight: 400;
  font-size: 20px;
  padding-left: 20px;
  position: relative;
  
}
.second-level-title{
  display: block;
  float: left;
  color: #333333;
  position: relative;
  .second-level-type{
    position: absolute;
    width: 30px;
    height: 19px;
    border-radius: 9px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color:#FFFFFF;
    top: -7px;
    right: -35px;
  }
}
.second-level-list{
  padding-left: 60px;
  height: 270px;
  width: 340px;
  display: flex;
  flex-direction: column;
  position: relative;
  .second-level-list-top{
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    p{
      font-size: 20px;
      margin-bottom: 0px;
      color: #A2A0A0;
    }
  }
  .second-level-list-line{
    position: absolute;
    height: 100%;
    width: 1px;
    background:#E8E8E8;
    right: -20px;
  }
}

.second-level:hover{
  transition: all .4s;
  background: #F8F8F8;
}
.second-level:hover .second-level-title{
  font-weight:bold;
  transition: all .4s;
}
.second-level-active {
  background: #F8F8F8 !important;
  font-weight: bold;
}
.second-level-box {
  padding-top: 35px;
}
.second-level-center {
  text-align: left;
  margin-left: -35px;
  align-items: center;
  padding-bottom: 25px;
}

.side-box {
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 100;
}
.side-item {
  width: 80px;
  height: 80px;
  transition: all 1s;
  background: #F6BB07;
  margin-bottom: 2px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.side-item-icon {
  width: 40px;
  height: 40px;
}
.side-right-frame {
  position: absolute;
  right: 80px;
  top: 164px;
  background: rgba(255,255,255,1);
  box-shadow: 0px 8px 30px 0px rgba(162,162,162,0.2);
  height: 80px;
  width: 220px;
  padding-left: 20px;
}
.side-right-frame span:nth-child(1) {
  color: #121213;
  font-size: 24px;
  font-weight:bold;
}
.side-right-frame span:nth-child(2) {
  color: #707070;
  font-size: 12px;
  opacity:0.6;
}
.side-right-frame2 {
  position: absolute;
  right: 80px;
  top: 88px;
  background: rgba(255,255,255,1);
  box-shadow: 0px 8px 30px 0px rgba(162,162,162,0.2);
  height: 238px;
  width: 220px;
}
.side-weChate-code {
  width: 160px;
  height: 160px;
}
.side-right-frame2 span {
  color: #707070;
  font-size: 14px;
}
.font-bold{
  font-weight: bold;
}
.title-line-between{
  width: 80px;
  border-radius: 4px;
  height: 8px;
  z-index: 99;
  margin:0 auto;
  background: linear-gradient(90deg, #FFCC0C, #F6BB07);
  box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
}
.menu-list{
  position: relative;
}