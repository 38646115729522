.head {
    height: 80px;
    // background: #ffffff;
}

.treeBox {
    max-width: 1250px;
    margin: 0 auto;
    padding-right: 20px;
}

.katalog {
    padding:40px 0;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    overflow-X: hidden;
    .katalogTitle {
        font-weight: bold;
    }
}

.content {
    font-size: 16px;
    background: #ffffff;
    overflow-y: auto;
    height: calc(100vh - 80px);
    padding: 40px;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
}
.content::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
}
/* 设置滚动条的样式 */

.katalog::-webkit-scrollbar ,.content::-webkit-scrollbar {
    width: 6px;
}

/* 滚动槽 */

.katalog::-webkit-scrollbar-track ,.content::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:10px;
}

/* 滚动条滑块 */
.katalog::-webkit-scrollbar-thumb  ,.content::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(0,0,0,0.1);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);

}


.antTree {
    font-size: 16px !important;
    :global(li .ant-tree-node-content-wrapper.ant-tree-node-selected){
        font-weight: bold !important;
        background: none !important;
        background: #f6bb07 !important;
    }
} 

.antTree {
    :global(li .ant-tree-node-content-wrapper:hover){
        font-weight: bold !important;
        background: none !important;
        background: #f6bb07 !important;
    }
} 


.antTree {
    :global(ant-tree-treenode-switcher-open .ant-tree-node-content-wrapper){
        font-weight: bold !important;
    }
} 
.antTree {
    :global(li span.ant-tree-switcher){
        background: transparent !important;
    }
}