.logical-system{
  display: flex;
  .logical-system-text-box{
    width: 400px;
    height: 100px;
    border-radius: 50px;
    padding-left: 20px;
    .logical-system-text{
      width: 265px;
      line-height: 30px;
      font-size: 20px;
    }
  }
  
}