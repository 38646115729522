.carousel-div{
  position: relative; 
  .carousel-box{
    width: 1400px;
    height: 670px;
    overflow: hidden;
    position: relative;
    .carousel-itme {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      right: 0;
      box-shadow: 1px 10px 30px 0px rgba(116, 128, 128, 0.2);
      margin: auto;
      transition: all 0.3s ease;
      border-radius: 20px;
      height:566px;
      width:262px;
      img{
        height: 100%;
        width: 100%;
        border-radius: 20px;
      }
      .carousel-but{
        width: 160px;
        height: 44px;
        background: #FFFFFF;
        border: 2px solid #17191A;
        border-radius: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #17191A;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 28px;
        cursor: pointer;
        transition: all .2s ;
        &:hover{
          background: #f6bb07;
          box-shadow: 0px 12px 12px 0px rgba(246, 187, 7, 0.24);
          border: 2px solid #17191a00;
        }
      }
    }
    .p0 {
      transform: translate3d(-842px, 0, 0) scale(0.7);
      opacity: 0;
    }
    .p1 {
      transform: translate3d(-560px, 0, 0) scale(0.8);
      z-index: 2;
    }
    .p2 {
      transform: translate3d(-298px, 0, 0) scale(0.9);
      z-index: 2;
    }
    
    .p3 {
      transform: translate3d(0, 0, 0) scale(1);
      z-index: 2;
      .carousel-but{
        margin-top: 52px;
      }
    }
    
    .p4 {
      transform: translate3d(298px, 0, 0) scale(0.9);
      z-index: 2;
    }
    
    .p5 {
      transform: translate3d(560px, 0, 0) scale(0.7);
      z-index: 2;
    }
    .p6 {
      transform: translate3d(842px, 0, 0) scale(0.7);
      opacity: 0;
    }
    .p7 {
      transform: translate3d(842px, 0, 0) scale(0.7);
      opacity: 0;
    }
    .p8 {
      transform: translate3d(842px, 0, 0) scale(0.7);
      opacity: 0;
    }
    .p9 {
      transform: translate3d(842px, 0, 0) scale(0.7);
      opacity: 0;
    }
    .p10 {
      transform: translate3d(842px, 0, 0) scale(0.7);
      opacity: 0;
    }
  }
  .extend{
    position: absolute;
    top: -200px;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .right-img{
    @extend .extend;
    right: 0;
  }
  .left-img{
    @extend .extend;
    left: 0;
  }
}

/* @keyframes dex {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */